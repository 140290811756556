import axios from 'axios';
import queryString from 'query-string'
import { alertError } from 'utils/helper/appHelper';
const quoteUrl = process.env.REACT_APP_SERVER_API;
const lineToken = localStorage.getItem('line:accessToken');

export const axiosClient = axios.create({
    baseURL: quoteUrl,
    headers: {
        'content-type': 'application/json',
        'Device-Type': 'Webapp',
        version: 9999,
        Authorization: `Bearer ${lineToken}`,
    }
    // paramsSerializer: (params) => queryString.stringify(params),
})

axiosClient.interceptors.response.use(
    (response: any) => {
        return response;
    },
    (error: any) => {
        // Handle errors
        let errorMessage = error;
        if (error.response) {
            if (error?.response?.status === 401) {
                if (window.location.href.includes('/line')) {
                    localStorage.removeItem('line:accessToken');
                    window.location.href = `${process.env.REACT_APP_SERVER_API_LINE}/auth/line`
                } else {
                    localStorage.removeItem('user');
                    window.location.reload()
                }
            }
            errorMessage =
                error.response.data?.message || error.response.data?.messages;
            if (error.response?.data?.error[0]) {
                alertError(error.response?.data?.error[0])
            }
        }
        const err = new Error(errorMessage);
        if (err.message.includes("Network Error")) {
            err.message = 'ネットワークエラー';
        }
        err.name = '';
        throw err;
    },
);

class QuoteApi {
    createSPQuote = (idToken: any, body: any) => {
        const url = 'line/quotes';
        const config: any = {
            headers: { Authorization: `Bearer ${idToken}` }
        }
        return axiosClient.post(url, body, config);
    };
    updateSPQuote = (idToken: any, body: any, id: any) => {
        const url = `line/quotes/${id}`;
        const config: any = {
            headers: { Authorization: `Bearer ${idToken}` },
        };
        return axiosClient.put(url, body, config);
    };

    getHistoryQuote = (idToken: any, param: any) => {
        const qs = queryString.stringify(param)
        const url = `quotes?${qs}`;
        const config: any = {
            headers: { Authorization: `Bearer ${idToken}` },
        };
        return axiosClient.get(url, config);
    }

    updateQuote = (idToken: any, body: any, id: any) => {
        const url = `quotes/${id}`;
        const config: any = {
            headers: { Authorization: `Bearer ${idToken}` },
        };
        return axiosClient.put(url, body, config);
    };
    createQuote = (idToken: any, body: any) => {
        const url = `quotes`;
        const config: any = {
            headers: { Authorization: `Bearer ${idToken}` },
        };
        return axiosClient.post(url, body, config);
    };
    getQuoteHistory = (status, idToken: any, page, text) => {
        let url = `${process.env.REACT_APP_SERVER_API_LINE_2
            }/quotes?status=${status}&page=${page}${text ? `&filter_value=${text}` : ''
            }`;
        if (status === 'all') {
            url = `${process.env.REACT_APP_SERVER_API_LINE_2
                }/quotes?page=${page}${text ? `&filter_value=${text}` : ''
                }`;
        }
        // if (status === "initial") {
        //   url += `&status=initial`;
        // }
        const config: any = {
            headers: { Authorization: `Bearer ${idToken}` },
        };
        return axiosClient.get(url, config);
    };
    getQuoteDetail = (idToken, id) => {
        let url = `quotes/${id}`;
        const config: any = {
            headers: { Authorization: `Bearer ${idToken}` },
        };
        return axiosClient.get(url, config);
    };

    getAllPartner = (idToken) => {
        const param = {
            all_partner: true
        }
        const qs = queryString.stringify(param)
        let url = `cooperative_companies?${qs}`;
        const config: any = {
            headers: { Authorization: `Bearer ${idToken}` },
        };
        return axiosClient.get(url, config);
    };

    getSPQuoteDetail = (idToken: any, id: any) => {
        const url = `${process.env.REACT_APP_SERVER_API_LINE_2}/quotes/${id}`;
        const config: any = {
            headers: { Authorization: `Bearer ${idToken}` },
        };
        return axiosClient.get(url, config);
    };

    deleteQuote = (idToken: any, id: any) => {
        const url = `quotes/${id}`;
        const config: any = {
            headers: { Authorization: `Bearer ${idToken}` },
        };
        return axiosClient.delete(url, config);
    };
}
const quoteApi = new QuoteApi();
export default quoteApi;
