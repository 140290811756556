import './App.css';
import SideBar from './components/SideBar';
import Header from './components/Header';

import { useSelector } from 'react-redux';
import Login from './pages/login/Login';

import Dashboard from './pages/dashboard';
import DashboardSP from './pages/dashboard/dashboardSP';
import TopSP from './pages/dashboard/topSP';
import ProjectCreate from './pages/project/projectCreate';
import Projects from './pages/project/index';
import ProjectsSP from './pages/project/projectsSP';
import ChatsSP from './pages/spchats/ChatsList';
import ChatControlSP from './pages/spchats/ChatControl';
import ProjectUpdate from './pages/project/projectUpdate';
import ChatBoxSP from './pages/spchats/ChatBox';
import ChatSP from './pages/spchats';
import WorkingReport from './pages/project/projectPlan';

import EstimationCreate from './pages/project/projectPlan/Estimations/EstimationCreate';
import EstimationEdit from './pages/project/projectPlan/Estimations/EstimationEdit';

import UserList from './pages/userList';
import Worker from './pages/worker';
import WorkerDetail from './pages/worker/wokerDetail';
import WorkerCreate from './pages/worker/workerCreate';
import WorkerEdit from './pages/worker/workerEdit';

import ClientCompany from './pages/clientCompany';
import ClientCompanyDetail from './pages/clientCompany/detail';
import ClientCompanyCreate from './pages/clientCompany/create';
import ClientCompanyEdit from './pages/clientCompany/edit';

import CooperativeCompanyDetail from 'pages/cooperativeCompany/detail';
import CooperativeCompanyCreate from 'pages/cooperativeCompany/create';
import CooperativeCompanyEdit from 'pages/cooperativeCompany/edit';

import CooperativeCompany from './pages/cooperativeCompany';

import Schedules from './pages/schedules';
import SchedulesSP from './pages/schedulesSP';
import SuggestionList from './pages/suggestions';
import SuggestionControl from './pages/suggestions/Suggestion';

import News from 'pages/news';

import ChatRoom from './pages/chats';
import ChatControl from './pages/chats/ChatControl';
import { useEffect, useState } from 'react';
import WebFont from 'webfontloader';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { RootState } from './store/index';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-dropdown/style.css';
import Setting from 'pages/setting';
import SPHeader from 'components/Header/sp-header';
import SPSidebar from 'components/SideBar/sp-sidebar';
import useMediaQuery from '@mui/material/useMediaQuery';
import ProjectDetailSP from 'pages/project/projectDetailSP';
import ProjectPlanSP from 'pages/project/projectPlan/projectPlanSP';
import WeeklyReportSP from 'pages/project/projectPlan/workingReportSP/WorkingReportList';
import WorkingReportCreateSP from 'pages/project/projectPlan/workingReportSP/WorkingReportCreate';
import SPTodo from 'pages/spTodo';
import SPJob from 'pages/spJob';
import SPJobCreate from 'pages/spJob/CreateJob';
import WeeklyReport from 'pages/weeklyReport';
import VehicleInspection from 'pages/vehicleInspection/desktop';
import CreateVehicleInspection from 'pages/vehicleInspection/desktop/create';
import VehicleInspectionSP from 'pages/vehicleInspection/smartphone';
import SPTarget from './pages/spTarget';
import InternalOJT from 'pages/internalOJT';
import CreateInternalOJT from 'pages/internalOJT/create';
import PrivateMessage from 'pages/privateMessage';
import PrivateMessageSP from 'pages/spPrivateMessage';
import MeetingMinutes from 'pages/meetingMinutes';
import MeetingMinutesCreate from 'pages/meetingMinutes/create';
import NewsSP from 'pages/spNews';
import WorkingTimes from 'pages/workingTimes';
import TimeKeeping from 'pages/timekeeping';
import WorkingTimesManagement from 'pages/WorkingTimesManagement';
import WorkingTimesManagementCreate from 'pages/WorkingTimesManagement/Create';
import StockNotes from 'pages/stockNotes';
import CreateStockNotes from 'pages/stockNotes/create';
import StockNoteSP from 'pages/stockNotes/smartphone';
import SPpurchaseRequest from 'pages/spPurchaseRequest';
import CreatePR from 'pages/spPurchaseRequest/CreatePR';
import OJTSP from 'pages/internalOJT/smartphone';
import InspectionRecords from 'pages/inspectionRecord';
import InspectionRecordSP from 'pages/inspectionRecordSP';
import TopSPv2 from 'pages/dashboard/topSPv2';
import Budget from 'pages/budget';
import BudgetCreate from 'pages/project/projectPlan/budgetAction/BudgetCreate';
import BudgetAction from 'pages/project/projectPlan/budgetAction';
import Compare from 'pages/compare';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SPQuoteList from 'pages/spQuote/list';
import QuoteList from 'pages/quote/list';
import QuoteForm from 'pages/quote/form';
import CompanyInfoSetting from 'pages/companyInfoSetting';
import Invoices from 'pages/invoices';
import InvoiceHome from 'pages/InvoiceHome';
import Partners from 'pages/partners';
import PartnerForm from 'pages/partners/PartnerForm';
import Customers from 'pages/customers';
import ProjectDetail from 'pages/quote/projectDetail';
import ProjectForm from 'pages/quote/projectForm';
import { TYPE_OF_SCREEN } from 'pages/project/projectPlan/projectTabs/constant';
import { MenuBookOutlined, MenuOutlined } from '@mui/icons-material';

const PrivateRoute = ({ isLoggedIn, children }) => {
    if (!isLoggedIn) {
        window.location.href = '/login';
    }

    return children;
};

function App() {
    const { isLoggedIn } = useSelector((state: RootState) => state.auth);
    const [isChangeTab, setChangeTab] = useState(false);
    const [isOpenMenu, setIsOpenMenu] = useState(false);
    const [isShowSideBar, setShowSideBar] = useState(true)
    const matchesSP = window?.innerWidth < 992;
    useEffect(() => {
        window.scrollTo(0, 1);
        WebFont.load({
            google: {
                families: ['Droid Sans', 'Roboto'],
            },
        });
    }, []);
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    const onHideSideBar = () => {
        setShowSideBar(!isShowSideBar)
    }

    useEffect(() => {
        if (matchesSP) {
            setShowSideBar(false)
        }
    }, [])
    return (
        <Router>
            <div className={isLoggedIn ? 'main d-flex flex-row' : 'main'}>
                {/* {isLoggedIn && (
                    <>{matchesSP ? <PrivateMessageSP /> : <PrivateMessage />}</>
                )} */}
                {isLoggedIn && (
                    <div className='flex flex-row relative min-h-[100vh]'>
                        {isShowSideBar && <SideBar
                            setChangeTab={setChangeTab}
                            isChangeTab={isChangeTab}
                            onHideSideBar={onHideSideBar}
                        />}
                        {/* {matchesSP && <div onClick={onHideSideBar} style={{
                            position: `${isShowSideBar ? 'fixed' : 'fixed'}`
                            , zIndex: '999999',
                            marginLeft: `${isShowSideBar ? '272px' : '0px'}`
                        }}
                            className='rounded-md w-[50px] cursor-pointer mt-[150px] h-[50px] bg-[#215493]' >
                            <MenuOutlined className='text-[#fff] !text-[40px] m-1' />
                        </div>} */}
                    </div>

                )}
                {/* {isLoggedIn && <SideBar
                    setChangeTab={setChangeTab}
                    isChangeTab={isChangeTab}
                />} */}
                {/* <SPSidebar
                    isOpenMenu={isOpenMenu}
                    setIsOpenMenu={setIsOpenMenu}
                /> */}
                <div className="content-body d-flex flex-column">
                    {/* {matchesSP ? (
                        <SPHeader
                            isOpenMenu={isOpenMenu}
                            setIsOpenMenu={setIsOpenMenu}
                        />
                    ) : (
                        <Header isChangeTab={isChangeTab}></Header>
                    )} */}
                    <Header isChangeTab={isChangeTab} onHideSideBar={onHideSideBar}></Header>

                    <Routes>
                        <Route
                            path="/"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    {/* {matchesSP ? <TopSPv2 /> : <QuoteList />} */}
                                    <QuoteList />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/working_times_management"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <WorkingTimesManagement />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/working_times_management/create"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <WorkingTimesManagementCreate />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/working_times_management/edit/:id"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <WorkingTimesManagementCreate />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/working_times_management/detail/:id"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <WorkingTimesManagementCreate isDetail />
                                </PrivateRoute>
                            }
                        />
                        {/* <Route path="/" element={<Dashboard />}/> */}
                        <Route path="/login" element={<Login />} />
                        <Route
                            path="/users"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <UserList />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/workers"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <Worker />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/workers/create"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <WorkerCreate screenType='create' />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/workers/edit/:id"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <WorkerCreate screenType='edit' />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/workers/detail/:id"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <WorkerDetail />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/projects/create"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <ProjectCreate />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/projects/edit/:id"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <ProjectUpdate />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/vehicle-inspection"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <VehicleInspection />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/meeting-minutes"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <MeetingMinutes />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/meeting-minutes/edit/:id"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <MeetingMinutesCreate />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/meeting-minutes/create"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <MeetingMinutesCreate />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/working_times"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <WorkingTimes />
                                </PrivateRoute>
                            }
                        />

                        <Route
                            path="/projects"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <Projects />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/sp/projects"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <ProjectsSP />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/sp/projects_list"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <ProjectsSP workingLogs />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/sp/project/:id/chats"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <ChatsSP />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/sp/project/:id/weekly-report"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <WeeklyReportSP />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/sp/project/:id/weekly-report/create"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <WorkingReportCreateSP />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/sp/project/:id/weekly-report/:report_id"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <WorkingReportCreateSP />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/sp/projects/:id/plans"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <ProjectPlanSP />
                                </PrivateRoute>
                            }
                        />

                        <Route
                            path="/sp/todo"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <SPTodo />
                                </PrivateRoute>
                            }
                        />

                        <Route
                            path="/sp/target"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <SPTarget />
                                </PrivateRoute>
                            }
                        />


                        <Route
                            path="/projects/detail/:id/plan"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <WorkingReport />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/projects/:project_id/chats/create"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <ChatControl />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/projects/:project_id/chats/:chat_id"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <ChatControl />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/sp/project/:id"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <ProjectDetailSP />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/weekly-report"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <WeeklyReport />
                                </PrivateRoute>
                            }
                        />

                        <Route
                            path="/projects/estimation/:id/create"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <EstimationCreate />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/projects/estimation/:id/edit/:estimationId"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <EstimationEdit />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/projects/budget/:id/create"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <BudgetCreate />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/projects/budget/:id/edit/:budgetId"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <BudgetAction />
                                </PrivateRoute>
                            }
                        />

                        <Route
                            path="/companies"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <ClientCompany />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/companies/detail/:id"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <ClientCompanyDetail />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/companies/create"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <ClientCompanyCreate />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/companies/edit/:id"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <ClientCompanyEdit />
                                </PrivateRoute>
                            }
                        />

                        <Route
                            path="/cooperative-companies"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <CooperativeCompany />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/cooperative-companies/detail/:id"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <CooperativeCompanyDetail />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/cooperative-companies/create"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <CooperativeCompanyCreate />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/cooperative-companies/edit/:id"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <CooperativeCompanyEdit />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/calendar"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <Schedules />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/sp/calendar"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <SchedulesSP />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/sp/chats"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <ChatSP />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="sp/chats/create"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <ChatControl isSP={true} />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/suggestions"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <SuggestionList />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/suggestions/create"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <SuggestionControl />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/suggestion/detail/:id"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <SuggestionControl />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/news"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <News />
                                </PrivateRoute>
                            }
                        />

                        <Route
                            path="/chats"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <ChatRoom />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/chats/detail/:chat_id"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <ChatControl />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/chats/create"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <ChatControl />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/sp/project/:id/chats/create"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <ChatControlSP />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="sp/project/:id/chats/chat/:chat_id"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <ChatBoxSP />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="sp/chats/detail/:chat_id"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <ChatBoxSP globalChat />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/setting"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <Setting />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/stock_notes"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <StockNotes />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/sp/stock_notes"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <StockNoteSP />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/stock_notes/edit/:id"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <CreateStockNotes />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/stock_notes/create"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <CreateStockNotes />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/sp/purchase-request"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <SPpurchaseRequest />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/sp/purchase-request/create"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <CreatePR />
                                </PrivateRoute>
                            }
                        />

                        <Route
                            path="/sp/purchase-request/detail/:id"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <CreatePR />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/sp/internal-ojt"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <OJTSP />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/inspection-records"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <InspectionRecords />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="sp/inspection-records"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <InspectionRecordSP />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/quote/list"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <QuoteList />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/quote/list/invoice"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <InvoiceHome />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/quote/edit/:id"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <QuoteForm screenType="edit" />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/quote/project/:id"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <ProjectDetail />
                                </PrivateRoute>
                            }
                        />

                        <Route
                            path="/quote/project/:id/edit"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <ProjectForm screenType={TYPE_OF_SCREEN.edit} />
                                </PrivateRoute>
                            }
                        />

                        <Route
                            path="/quote/create"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <QuoteForm screenType="create" />
                                </PrivateRoute>
                            }
                        />

                        <Route
                            path="/quote/:id"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <QuoteForm screenType="detail" />
                                </PrivateRoute>
                            }
                        />

                        <Route
                            path="/sp/quote/list"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <SPQuoteList />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/company-info-setting"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <CompanyInfoSetting />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/invoices"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <Invoices />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/compare"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <Compare />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/partners"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <Partners />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/partners/create"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <PartnerForm screenType="create" />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/partners/:id"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <PartnerForm screenType="detail" />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/partners/edit/:id"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <PartnerForm screenType="edit" />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/customers"
                            element={
                                <PrivateRoute isLoggedIn={isLoggedIn}>
                                    <Customers />
                                </PrivateRoute>
                            }
                        />
                    </Routes>
                </div>
            </div>
        </Router>
    );
}

export default App;
