import { message, UploadProps } from 'antd';
import { useState, useEffect, FocusEventHandler, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { alertSuccess, alertSuccessPopup, validateZipcode } from 'utils/helper/appHelper';
import quoteApi from 'services/quoteApi';
import moment from 'moment';
import { convertErrorMessagesToObject, validateFullWhitespace } from 'utils/validation/validatorHelper';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import Upload, { RcFile, UploadChangeParam, UploadFile } from 'antd/lib/upload';
import departmentsService from 'services/departmentsService';
import staffApi from 'services/staffApi';
import { TYPE_OF_SCREEN } from 'pages/project/projectPlan/projectTabs/constant';
import { makeCode } from 'utils/helper/randomHelper';

export type ReceivedProps = {
    screenType: "create" | "edit" | "detail";
};
const useWorkerForm = (props: ReceivedProps) => {
    const { screenType } = props;
    const {
        control,
        setValue,
        register,
        handleSubmit,
        formState: { errors },
        clearErrors,
        watch,
        getValues,
        setError,
    } = useForm();
    const [loading, setLoading] = useState<boolean>(true);
    const id = useParams();
    const navigate = useNavigate();
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    const [isLoading, setIsLoading] = useState(false);
    const { positionsList } = useSelector(
        (state: RootState) => state.positions,
    );
    const { departmentList } = useSelector(
        (state: RootState) => state.deparments,
    );
    const [companyOption, setCompanyOption] = useState<any>([]);
    const findZipcodeTimer = useRef<NodeJS.Timeout>();
    const [indexError, setIndexError] = useState(0);
    const [sizeUploadLimitError, setSizeUploadLimitError] =
        useState<boolean>(false);
    const [positionOption, setPositionOption] = useState<any>([]);
    const [departmentOption, setDepartmentOption] = useState<any>([]);
    const [isAllowUpload, setAllowUpload] = useState(true);
    const [fileLength, setFileLength] = useState<number>(0);
    const [listGenderValue, setListGenderValue] = useState<any>(null);

    const [deleteError, setDeleteError] = useState<any>(null)
    const [fileList, setFileList] = useState<any>([])
    const [previewOpen, setPreviewOpen] = useState(false)
    const [previewImage, setPreviewImage] = useState('')
    const [previewTitle, setPreviewTitle] = useState('')
    const [totalImageSize, setTotalImageSize] = useState<number>(0);
    const [listFile, setListFile]: any = useState([]);
    const UPLOAD_LIMIT = 50;

    const dragProps: UploadProps = {
        name: "file",
        onChange(info) {
            console.log("onChange info", info);
        },
        onDrop(e) {
            console.log("Dropped files", e.dataTransfer.files);
        },
    };
    const dummyRequest: UploadProps["customRequest"] = async ({
        file,
        onSuccess
    }) => {
        setTimeout(() => {
            if (onSuccess) {
                onSuccess("ok");
            }
        }, 0);
    };


    const handleClose = () => {
        clearErrors();
        setTotalImageSize(0);
        navigate('/workers')
    };

    const getDetail = async () => {
        try {
            if (screenType === TYPE_OF_SCREEN.edit) {
                const res = await staffApi?.getStaffDetail(user?.access_token, id?.id)
                if (res) {
                    const data = res?.data?.staff
                    setValue('code', data?.code)
                    setValue('name', data?.name)
                    setValue('furigana_name', data?.furigana_name)
                    setValue('mobile_number', data?.mobile_number)
                    // setValue('email', data?.email)
                    setValue('gender', data?.gender)
                    // setValue('uniform_size', data?.uniform_size)
                    setValue('insurance', data?.insurance)
                    setValue('department', data?.department)
                    setValue('role', data?.role)
                    setValue('postal_code', data?.postal_code)
                    setValue('address1', data?.address1)
                    // setValue('address2', data?.address2)
                    data?.personal_number && setValue('personal_number', data?.personal_number)
                    // data?.birth_date && setValue('birth_date', moment(data?.birth_date))
                    data?.entry_date && setValue('entry_date', moment(data?.entry_date))
                    data?.leaving_date && setValue('leaving_date', moment(data?.leaving_date))
                    setValue('bank_name', data?.bank_info?.bank_name)
                    setValue('branch_name', data?.bank_info?.branch_name)
                    setValue('account_type', data?.bank_info?.account_type)
                    setValue('account_holder_kanji', data?.bank_info?.account_holder_kanji)
                    data?.bank_info?.account_holder_kana && setValue('account_holder_kana', data?.bank_info?.account_holder_kana)
                    setValue('account_number', data?.bank_info?.account_number)
                    setValue('emergency_contact_name', data?.emergency_contact?.name)
                    setValue('emergency_contact_relationship', data?.emergency_contact?.relationship)
                    setValue('emergency_contact_phone', data?.emergency_contact?.contact_phone_number)
                    let imgFile = {}
                    if (data?.image) {
                        await fetch(data?.image.file_path)
                            .then((res) => res.blob())
                            .then((myBlob) => {
                                const myFile: any = new File(
                                    [myBlob],
                                    'image.jpeg',
                                    { type: myBlob.type },
                                );
                                imgFile = {
                                    uid: `-1`,
                                    name: myFile.name,
                                    url: data?.image.file_path,
                                    status: 'done',
                                    originFileObj: myFile,
                                    lastModified: myFile.lastModified,
                                    lastModifiedDate: myFile.lastModifiedDate,
                                    type: myFile.type,
                                    size: myFile.size,
                                }
                            });
                    }
                    setValue('images', data?.image.file_path);
                    setListFile([imgFile]);
                }
            } else {

            }
        } catch (error) {
            console.log("error")
            setIsLoading(false);
        }
    }

    const submitData = async (value: any, evt: any) => {
        // evt.preventDefault()
        if (value?.zipCode) {
            const isZipcodeFormat = validateZipcode(value?.zipCode);
            if (!isZipcodeFormat) {
                setError('zipCode', {
                    type: 'validate',
                    message:
                        '郵便番号は数字のみで、000-0000の形式でなければなりません。',
                });
                return;
            }
        }
        setIsLoading(true);
        let formData = new FormData();
        if (typeof value?.images === 'string' && value?.images === listFile[0]?.url) {
            formData.append('image', listFile[0]?.originFileObj);
        } else {
            formData.append('image', value?.images);
        }
        // if (screenType === TYPE_OF_SCREEN.create) {
        //     formData.append('code', makeCode(10));
        // } else {
        //     formData.append('code', value?.code || '');
        // }

        formData.append('name', value?.name);
        formData.append('furigana_name', value?.furigana_name);
        formData.append('mobile_number', value?.mobile_number);
        // formData.append('email', value?.email);
        // value?.password && formData.append('password', value?.password);
        value?.gender && formData.append('gender', value?.gender);
        value?.entry_date && formData.append('entry_date', value?.entry_date);
        value?.leaving_date && formData.append('leaving_date', value?.leaving_date);
        value?.role && formData.append('role', value?.role)
        value?.insurance && formData.append('insurance', value?.insurance)
        value?.department && formData.append('department', value?.department)
        value?.postal_code && formData.append('postal_code', value?.postal_code)
        value?.address1 && formData.append('address1', value?.address1)
        value?.personal_number && formData.append('personal_number', value?.personal_number)
        value?.bank_name && formData.append('bank_info[bank_name]', value?.bank_name);
        value?.branch_name && formData.append('bank_info[branch_name]', value?.branch_name);
        value?.account_type && formData.append('bank_info[account_type]', value?.account_type);
        value?.account_holder_kanji && formData.append('bank_info[account_holder_kanji]', value?.account_holder_kanji);
        value?.account_holder_kana && formData.append('bank_info[account_holder_kana]', value?.account_holder_kana);
        value?.account_number && formData.append('bank_info[account_number]', value?.account_number);

        value?.emergency_contact_name && formData.append('emergency_contact[name]', value?.emergency_contact_name);
        value?.emergency_contact_relationship && formData.append('emergency_contact[relationship]', value?.emergency_contact_relationship);
        value?.emergency_contact_phone && formData.append('emergency_contact[contact_phone_number]', value?.emergency_contact_phone);
        try {
            if (screenType === TYPE_OF_SCREEN.create) {
                const res = await staffApi?.createStaff(user?.access_token, formData)
                if (res) {
                    alertSuccessPopup({
                        title: '作成しました',
                        confirmButtonText: '閉じる',
                    });
                    // navigate('/workers')
                }
            } else {
                const res = await staffApi?.updateStaff(user?.access_token, formData, id?.id)
                if (res) {
                    alertSuccessPopup({
                        title: '作成しました',
                        confirmButtonText: '閉じる',
                    });
                    // navigate('/workers')
                }
            }
            handleClose();
        } catch (error) {
            console.log("error")
            setIsLoading(false);
        }
    };


    const handleValidateWhitespace: FocusEventHandler<
        HTMLInputElement | HTMLTextAreaElement
    > = (e) => {
        const onlyContainWhitespaces = validateFullWhitespace(e.target.value);
        if (onlyContainWhitespaces) {
            setValue(e.target.name, '');
        } else {
            setValue(e.target.name, e.target.value.trim());
        }
    };

    const getBase64 = (file: any) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = (error) => reject(error)
        })

    const handlePreview = async (file: any) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj)
        }
        setPreviewImage(file.url || file.preview)
        setPreviewOpen(true)
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1))
    }

    const handleRemove = (file) => {
        setValue('images', '')
        setError('images', {
            type: 'validate',
            message: '',
        })
    }

    const handleChangeFile = (info: UploadChangeParam<UploadFile<any>>) => {
        const loadFile = 10;
        setIndexError(0);
        const filteredPdfAfterUid = info?.fileList.filter((bItem) =>
            listFile?.some((aItem) => aItem.uid === bItem.uid),
        );
        const filteredPdfAfterRedundant = info?.fileList.filter(
            (bItem) => !listFile?.some((aItem) => aItem.uid === bItem.uid),
        );
        const filteredPdfAfterReMove: any =
            loadFile < filteredPdfAfterRedundant?.length &&
            filteredPdfAfterRedundant?.slice(0, loadFile > 1 ? loadFile : 1);

        if (info.file.status === 'done') {
            setTotalImageSize(
                (prevSize) => prevSize + (info.file.size as number),
            );
        }
        if (info.file.status === 'removed') {
            setTotalImageSize(
                (prevSize) => prevSize - (info.file.size as number),
            );
        }
        if (loadFile >= info?.fileList?.length) {
            setAllowUpload(info.fileList.length < 10);
            setValue('images', [info.fileList]);
            setFileLength(info?.fileList?.length);
            setListFile([info?.fileList]);
        } else {
            setAllowUpload(info.fileList.length < 10);
            setValue('images', [info.fileList]);
            setFileLength(info?.fileList?.length);
            setListFile([info?.fileList]);
            setIndexError((index) => index + 1);
        }
        clearErrors('images');
    };

    const handleBeforeUpload = (file: RcFile, fileList: RcFile[]) => {
        const uploadingSizes = fileList.reduce((accu: number, curr: RcFile) => {
            return accu + curr.size;
        }, 0);
        setIndexError(0);
        const accumulatedImageSize = totalImageSize + uploadingSizes;
        const isImage =
            file.type === 'image/jpeg' ||
            file.type === 'image/png' ||
            file.type === 'image/gif';
        if (!isImage) {
            message.error(
                'jpg、.jpeg、.png、.gif形式でアップロードしてください。',
            );
            return Upload.LIST_IGNORE;
        }

        const isUnderLimit = accumulatedImageSize < 1024 * 1024 * UPLOAD_LIMIT;
        if (!isUnderLimit) {
            setSizeUploadLimitError(true);
            return Upload.LIST_IGNORE;
        }

        return isImage && isUnderLimit;
    };

    const parsePostalCode = (zipCode: string) => {
        if (!zipCode) {
            setError('postal_code', {
                type: 'required',
                message: '郵便番号を入力してください。',
            });
            return;
        }

        const isZipcodeFormat = validateZipcode(zipCode);

        if (!isZipcodeFormat) {
            setError('postal_code', {
                type: 'validate',
                message:
                    '郵便番号は数字のみで、000-0000の形式でなければなりません。',
            });
            return;
        }

        if (findZipcodeTimer.current) {
            clearTimeout(findZipcodeTimer.current);
        }

        let foundAddress: string;
        const postalCode = require('japan-postal-code');

        postalCode.get(zipCode, function (address) {
            foundAddress = `${address?.prefecture}${address?.city}${address?.area}`;
        });

        findZipcodeTimer.current = setTimeout(() => {
            if (foundAddress) {
                setValue('address1', foundAddress);
                clearErrors('address1');
                clearErrors('postal_code');
            } else {
                setValue('address1', '');
                setError('postal_code', {
                    type: 'validate',
                    message: '入力された郵便番号に該当する住所が存在しません。',
                });
            }
        }, 1000);
    };

    useEffect(() => {
        setValue('mobile_number', '')
        setValue('personal_number', '')
        setValue('account_number', '')
        // setValue('mobile_number', '')
        if (id) {
            getDetail()
        }
    }, [])

    return {
        ...props,
        handleSubmit,
        submitData,
        control,
        dragProps,
        dummyRequest,
        setValue,
        register,
        clearErrors,
        watch,
        getValues,
        setError,
        errors,
        navigate,
        fileLength,
        isAllowUpload,
        listFile,
        setFileLength,
        setAllowUpload,
        setListFile,
        totalImageSize,
        setTotalImageSize,
        handleValidateWhitespace,
        screenType,
        positionOption,
        indexError,
        departmentOption,
        sizeUploadLimitError,
        listGenderValue,
        deleteError,
        fileList,
        previewOpen,
        previewImage,
        previewTitle,
        handleRemove,
        handleChangeFile,
        handleBeforeUpload,
        parsePostalCode,
        handlePreview
    };
};

export type Props = ReturnType<typeof useWorkerForm>;

export default useWorkerForm;
