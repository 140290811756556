import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Chart, ReactGoogleChartEvent } from "react-google-charts";
import { TimeLineWrapper } from './style';
import './timeStyle.scss'
import { useNavigate } from 'react-router-dom';

export const columnsGanttSchedule = [
    { type: "string", label: "Task ID" },
    { type: "string", label: "Task Name" },
    { type: "string", label: "Resource" },
    { type: "date", label: "Start Date" },
    { type: "date", label: "End Date" },
    { type: "number", label: "Duration" },
    { type: "number", label: "Percent Complete" },
    { type: "string", label: "Dependencies" },
];

const rows = [
    [
        '2014Spring',
        "Project name\n Jan 31 - Feb 4 \n Project manager",
        "#000",
        new Date(2014, 2, 22),
        new Date(2014, 5, 20),
        null,
        100,
        null,
    ],
    [
        "2014Summer",
        "Project name 2\n Feb 2 - Feb 4 \n Project manager 2",
        "#000",
        new Date(2014, 5, 21),
        new Date(2014, 8, 20),
        null,
        100,
        null,
    ],
    [
        "2014Autumn",
        "Autumn 2014",
        "#111",
        new Date(2014, 8, 21),
        new Date(2014, 11, 20),
        null,
        100,
        null,
    ],
    [
        "2014Winter",
        "Winter 2014",
        "#000",
        new Date(2014, 11, 21),
        new Date(2015, 2, 21),
        null,
        100,
        null,
    ],
];


export const data: any = [columnsGanttSchedule, ...rows];


export type ReceivedProps = {
    setOpen: Dispatch<SetStateAction<boolean>>;
    scheduleGantt: any[];
    setDetailId: Dispatch<SetStateAction<any>>;
    scheduleList: any[];
    listColor: string[]
    activeTab: number;
};

export const columnsTime = [
    { type: "string", id: "President" },
    { type: "string", id: "Name" },
    { type: "date", id: "Start" },
    { type: "date", id: "End" },
];

const rowsTime = [
    ["1", 'title', new Date(1789, 3, 30), new Date(1797, 2, 4)],
    ["2", 'title2', new Date(1797, 2, 4), new Date(1801, 2, 4)],
    ["3", 'title3', new Date(1801, 2, 4), new Date(1809, 2, 4)],
    ["4", 'title4', new Date(1801, 2, 4), new Date(1809, 2, 4)],
];

export const dataTime = [columnsTime, ...rowsTime];

const TimeLine = ({ setOpen, scheduleGantt, setDetailId, scheduleList, listColor, activeTab }: ReceivedProps) => {
    const [widthTimeLine, setWidthTimeLine] = useState(window.innerWidth);
    const navigate = useNavigate()
    const optionsTime = {
        timeline: {
            groupByRowLabel: true,
            showRowLabels: true,
            dateFormat: 'YYYY/MM/DD',
            rowLabelStyle: {
                fontName: 'Helvetica',
                fontSize: 0.1,
                color: '#000000'
            },
            rowHeight: 102.5,
            barLabelStyle: {
                fontSize: 25,
            }
        },
        colors: listColor,
        'width': widthTimeLine > 1000 ? widthTimeLine - 500 : 1100,
        'height': scheduleGantt.length > 0 ? scheduleGantt.length * 85.4 + 50 : 200,
        'backgroundColor': '#D0DEF5',
        'chartArea': {
            width: '80%', // make sure this is the same for the chart and control so the axes align right
            height: '80%'
        },

    };


    const chartEvents: ReactGoogleChartEvent[] = [
        {
            eventName: 'select',
            callback: ({ chartWrapper, controlWrapper, props, google, eventArgs }) => {
                if (chartWrapper) {
                    const chart = chartWrapper.getChart();
                    // const data = chartWrapper.getDataTable()
                    const selection = chart.getSelection();
                    // const newData = data?.getTableProperties()
                    if (selection.length > 0) {
                        const row = selection[0].row;
                        const rowData = scheduleGantt[row + 1]; // first element is column array
                        if (activeTab === 1) {
                            setDetailId(rowData[0])
                            setOpen(true)
                        } else {
                            navigate(`/quote/project/${rowData[0]}`)
                        }

                    }
                }
            },
        },
    ];

    useEffect(() => {
        const handleResize = () => setWidthTimeLine(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <TimeLineWrapper className='pt-[50px] overflow-x-scroll overflow-y-hidden' >
            {scheduleGantt && scheduleGantt.length > 1 ? <div className='flex flex-row !w-[1300px] chart-custom' >
                <div className='!w-[200px] ' >
                    {scheduleList && scheduleList?.map((item, index) => (
                        <div key={index} className='h-[85.4px] p-1' style={{
                            border: '1px solid #e6e6e6',
                            borderRight: `3px solid ${item?.color}`
                        }} >
                            {activeTab === 1 ? <>
                                <div className='font-bold whitespace-nowrap overflow-hidden text-ellipsis' >社員: {item?.staff_name}</div>
                                <div className='text-[#3C98CC] text-[14px] mt-[10px] overflow-hidden text-ellipsis' >{item?.start_date} ~ {item?.end_date}</div>
                                <div className='text-[#979797] whitespace-nowrap overflow-hidden text-ellipsis' >{item?.project_title}</div>
                            </> : <>
                                <div className='font-bold whitespace-nowrap overflow-hidden text-ellipsis' >{item?.project_title}</div>
                                <div className='text-[#3C98CC] text-[14px] mt-[10px] overflow-hidden text-ellipsis' >{item?.start_date} ~ {item?.end_date}</div>
                                <div className='text-[#979797] whitespace-nowrap overflow-hidden text-ellipsis' >{item?.staff_name}</div>
                            </>}

                        </div>
                    ))}
                </div>
                <Chart chartType="Timeline"
                    data={scheduleGantt}
                    options={optionsTime}
                    chartEvents={chartEvents}
                    chartLanguage={'ja'} />
            </div> : ''}
        </TimeLineWrapper>
    )
}

export default TimeLine