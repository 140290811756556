import { FC } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import useFilterBox, { Props, ReceivedProps } from './hook';
import FilterBoxWrapper from './style';
import { Input, Select } from 'antd';

const FilterBoxLayout: FC<Props> = ({
    formik,
    workerOptions,
    positionOptions,
    departmentOptions,
    quoteOptions,
    activeTab,
}) => {
    return (
        <FilterBoxWrapper>
            <form onSubmit={formik.handleSubmit} className="filterBox">
                <div className="flex flex-col lg:grid lg:grid-cols-4 gap-[15px] !w-full">

                    {activeTab === 0 && <div className="w-full">
                        <span className="fieldLabel">案件名</span>
                        <div className="w-full">
                            <Select
                                options={quoteOptions}
                                showSearch
                                showArrow
                                allowClear
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                onChange={(event, newValue) => {
                                    formik.setFieldValue(
                                        'position_id',
                                        newValue,
                                    );
                                }}
                                // emptyMessage={'該当なし'}
                                style={{ height: '40px !important', width: '100%' }}
                                placeholder=""
                            />
                        </div>
                    </div>}

                    {activeTab === 1 && <div className="w-full">
                        <span className="fieldLabel">社員</span>
                        <div className="w-full">
                            <Select
                                options={workerOptions}
                                showSearch
                                showArrow
                                allowClear
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                onChange={(event, newValue) => {
                                    formik.setFieldValue(
                                        'worker_id',
                                        newValue,
                                    );
                                }}
                                // emptyMessage={'該当なし'}
                                style={{ height: '40px !important', width: '100%' }}
                                placeholder=""
                            />
                        </div>
                    </div>}

                    <div className="w-full">
                        <span className="fieldLabel">キーワード</span>
                        <div className="wrapTextField !w-full haveIcon">
                            <Input
                                name="free_word"
                                type="text"
                                className='!w-full'
                                style={{ background: '#fff', border: '1px solid #d9d9d9', height: '40px' }}
                                onChange={formik.handleChange}
                                value={formik.values.free_word || ''}
                            />
                        </div>
                    </div>

                    <div className="w-full">
                        <button
                            type="submit"
                            className="modal-create-plan-button2 d-flex flex-row mt-[20px]"
                            style={{
                                width: 100,
                                marginLeft: 0,
                                height: '40px',
                            }}
                        >
                            検索
                        </button>
                    </div>
                </div>
            </form>
        </FilterBoxWrapper>
    );
};

const FilterBox: FC<ReceivedProps> = (props) => (
    <FilterBoxLayout {...useFilterBox(props)} />
);

export default FilterBox;
