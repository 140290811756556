import { FC, useEffect, useRef, useState } from 'react';
import { DatePicker, message, Select, Upload } from 'antd';
import { Controller } from 'react-hook-form';
import SelectSearch, { SelectSearchOption } from 'react-select-search';
import useProjectForm, { Props, ReceivedProps } from './hook';
import { QuoteFormStyles } from './styled';
import { QuoteStatusEnum } from 'utils/enums';
import { handleFilter } from 'utils/helper/filterDropdown';
import uploadIcon from 'assets/icons/addImageFile.svg';
import warningIcon from 'assets/icons/WarningCircle.svg';
import { validateZipcode } from 'utils/helper/appHelper';
import { useTranslation } from 'react-i18next';
import { RcFile, UploadChangeParam, UploadFile } from 'antd/lib/upload';
import styled from 'styled-components';
import quoteValidation from 'utils/validation/registers/quote';
import { fullWidthNumConvert } from 'pages/invoice';
import { validZipCode } from 'utils/validation/registers/validator';
import 'react-select-search/style.css'
import { MAX_LENGTH, OPTION_NONE, TYPE_OF_SCREEN } from 'pages/project/projectPlan/projectTabs/constant';
import { ProjectTabelRow } from '../projectDetail';
import projectQuoteValidation from 'utils/validation/registers/quoteProject';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { formatCurrency } from 'utils/helper/currency';

const { RangePicker } = DatePicker;

const statusOptions = Object.values(QuoteStatusEnum).map((item) => ({
    value: item.value,
    name: item.title,
    label: item.title,
}));

const ProjectFormLayout: FC<Props> = ({
    handleSubmit,
    submitData,
    control,
    dummyRequest,
    setValue,
    register,
    clearErrors,
    getValues,
    setError,
    errors,
    navigate,
    fileLength,
    isAllowUpload,
    listFile,
    setFileLength,
    setAllowUpload,
    setListFile,
    totalImageSize,
    setTotalImageSize,
    handleValidateWhitespace,
    screenType,
    partnerOptions,
    isDisable,
    staffOptions
}) => {
    const [indexError, setIndexError] = useState(0);
    const { id } = useParams()
    const { t } = useTranslation();
    const findZipcodeTimer = useRef<NodeJS.Timeout>();
    const UPLOAD_LIMIT = 50;
    const [sizeUploadLimitError, setSizeUploadLimitError] =
        useState<boolean>(false);
    useEffect(() => {
        if (sizeUploadLimitError) {
            message.error(
                `画像の合計サイズは ${UPLOAD_LIMIT}MB を超えてはなりません`,
            );
            setSizeUploadLimitError(false);
        }
    }, [sizeUploadLimitError]);

    const wordLimit = 5000;

    const parsePostalCode = (zipCode: string, type: string) => {
        if (!zipCode) {
            if (type === 'customer') {
                setError('zipCode', {
                    type: 'required',
                    message: '郵便番号を入力してください。',
                });
            } else {
                setError('zipCodeConstruction', {
                    type: 'required',
                    message: '郵便番号を入力してください。',
                });
            }

            return;
        }

        const isZipcodeFormat = validateZipcode(zipCode);
        if (!isZipcodeFormat) {
            if (type === 'customer') {
                setError('zipCode', {
                    type: 'validate',
                    message:
                        '郵便番号は数字のみで、000-0000の形式でなければなりません。',
                });
            } else {
                setError('zipCodeConstruction', {
                    type: 'required',
                    message: '郵便番号は数字のみで、000-0000の形式でなければなりません。',
                });
            }

            return;
        }

        if (findZipcodeTimer.current) {
            clearTimeout(findZipcodeTimer.current);
        }

        let foundAddress: string;
        const postalCode = require('japan-postal-code');
        postalCode.get(zipCode, function (address) {
            foundAddress = `${address?.prefecture}${address?.city}${address?.area}`;
        });

        findZipcodeTimer.current = setTimeout(() => {
            if (foundAddress) {
                if (type === 'customer') {
                    setValue('address', foundAddress);
                    clearErrors('address');
                    clearErrors('zipCode')
                } else {
                    setValue('construction_address', foundAddress);
                    clearErrors('construction_address');
                    clearErrors('zipCodeConstruction');
                }

            } else {
                if (type === 'customer') {
                    setValue('address', '');
                    setError('zipCode', {
                        type: 'validate',
                        message: '入力された郵便番号に該当する住所が存在しません。',
                    });
                } else {
                    setValue('construction_address', '');
                    setError('zipCodeConstruction', {
                        type: 'validate',
                        message: '入力された郵便番号に該当する住所が存在しません。',
                    });
                }

            }
        }, 1000);
    };
    useEffect(() => {
        return () => {
            if (findZipcodeTimer.current) {
                clearTimeout(findZipcodeTimer.current);
            }
        };
    }, []);

    const handleChangeFile = (info: UploadChangeParam<UploadFile<any>>) => {
        const loadFile = 10;
        setIndexError(0);
        const filteredPdfAfterRedundant = info?.fileList.filter(
            (bItem) => !listFile?.some((aItem) => aItem.uid === bItem.uid),
        );

        if (info.file.status === 'done') {
            setTotalImageSize(
                (prevSize) => prevSize + (info.file.size as number),
            );
        }
        if (info.file.status === 'removed') {
            setTotalImageSize(
                (prevSize) => prevSize - (info.file.size as number),
            );
        }
        if (loadFile >= info?.fileList?.length) {
            setAllowUpload(info.fileList.length < 10);
            setValue('images', info.fileList);
            setFileLength(info?.fileList?.length);
            setListFile(info?.fileList);
        } else {
            setIndexError((index) => index + 1);
        }
        clearErrors('images');
    };

    const handleBeforeUpload = (file: RcFile, fileList: RcFile[]) => {
        const uploadingSizes = fileList.reduce((accu: number, curr: RcFile) => {
            return accu + curr.size;
        }, 0);
        setIndexError(0);
        const accumulatedImageSize = totalImageSize + uploadingSizes;
        const isImage =
            file.type === 'image/jpeg' ||
            file.type === 'image/png' ||
            file.type === 'image/gif';
        if (!isImage) {
            message.error(
                'jpg、.jpeg、.png、.gif形式でアップロードしてください。',
            );
            return Upload.LIST_IGNORE;
        }

        const isUnderLimit = accumulatedImageSize < 1024 * 1024 * UPLOAD_LIMIT;
        if (!isUnderLimit) {
            setSizeUploadLimitError(true);
            return Upload.LIST_IGNORE;
        }

        return isImage && isUnderLimit;
    };

    const uploadButton = (
        <div className="text-[#5392F0] flex flex-col items-center">
            <img
                src={uploadIcon}
                className="w-[21px] h-[21px]"
                alt="upload image icon"
            />
            <div>写真 ({fileLength > 0 ? fileLength : 0}/10)</div>
        </div>
    );

    useEffect(() => {
        indexError === 1 &&
            message.error('ファイルアップロードは10ファイルまでです。');
    }, [indexError]);

    return (
        <QuoteFormStyles className="p-[24px]">
            <div className="bg-[#fff] quote-form-container rounded-[4px] p-[12px]">
                <p className="text-[20px] font-bold" >案件名</p>
                <form onSubmit={handleSubmit(submitData)}>
                    <div className="grid md:grid-cols-2" >
                        <div className="project-detail-inf-row-sp grid md:grid-cols-4">
                            <div className="project-detail-inf-row-title-sp-3 max-md:h-[40px] !text-[16px] max-md:col-span-4 md:col-span-1">案件ID</div>
                            <BorderDiv className="project-detail-inf-row-content-sp-2 max-md:min-h-[40px] max-md:col-span-4 md:col-span-3">
                                <span className='ml-[10px]' >{getValues('id')}</span>
                            </BorderDiv>
                        </div>
                        <div className="project-detail-inf-row-sp grid md:grid-cols-4">
                            <div className="project-detail-inf-row-title-sp-3 max-md:h-[40px] !text-[16px] max-md:col-span-4 md:col-span-1">ステータス</div>
                            <BorderDiv className="project-detail-inf-row-content-sp-2 max-md:min-h-[40px] max-md:col-span-4 md:col-span-3 flex !flex-col !pl-0">
                                <Controller
                                    name="status"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            options={statusOptions}
                                            {...register('status', projectQuoteValidation(t).status_project())}
                                            // search
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            {...field}
                                            // emptyMessage={'該当なし'}
                                            style={{
                                                height: '45px !important', width: '100%', paddingTop: '0px', paddingBottom: '0px', fontSize: '16px'
                                            }}
                                            placeholder=""
                                        />
                                    )}

                                />
                                {errors.status && (
                                    <span className="error w-full !text-left pl-[10px]">
                                        {errors.status.message?.toString()}
                                    </span>
                                )}
                            </BorderDiv>
                        </div>
                        <div className="project-detail-inf-row-sp grid md:grid-cols-4">
                            <div className="project-detail-inf-row-title-sp-3 max-md:h-[40px] !text-[16px] max-md:col-span-4 md:col-span-1">案件名</div>
                            <BorderDiv className="project-detail-inf-row-content-sp-2 max-md:min-h-[40px] max-md:col-span-4 md:col-span-3 flex !flex-col !pl-0">
                                <input
                                    autoComplete="off"
                                    type="text"
                                    className="w-full px-[10px] !text-[16px]"

                                    maxLength={MAX_LENGTH}
                                    {...register('project_title', projectQuoteValidation(t).project_name())}
                                    onBlur={handleValidateWhitespace}
                                />
                                {errors.project_title && (
                                    <span className="error w-full !text-left pl-[10px]">
                                        {errors.project_title.message?.toString()}
                                    </span>
                                )}
                            </BorderDiv>
                        </div>
                        <div className="project-detail-inf-row-sp grid md:grid-cols-4">
                            <div className="project-detail-inf-row-title-sp-3 max-md:h-[40px] !text-[16px] max-md:col-span-4 md:col-span-1">期間</div>
                            <BorderDiv className="project-detail-inf-row-content-sp-2 max-md:min-h-[40px] max-md:col-span-4 md:col-span-3 flex !flex-col !pl-0">
                                <Controller
                                    name="period"
                                    control={control}
                                    render={({ field }) => (
                                        <div className="!w-[100%]">
                                            <RangePicker
                                                {...register('period', projectQuoteValidation(t).period())}
                                                {...field}
                                                className='rounded-sm !text-[16px]'
                                                style={{ height: '40px', width: '100%' }} />
                                            <br />
                                            {errors.period && (
                                                <span className="error w-full !text-left pl-[10px]">
                                                    {errors.period.message?.toString()}
                                                </span>
                                            )}
                                        </div>

                                    )}
                                />

                            </BorderDiv>
                        </div>
                        <div className="project-detail-inf-row-sp grid md:grid-cols-4">
                            <div className="project-detail-inf-row-title-sp-3 max-md:h-[40px] !text-[16px] max-md:col-span-4 md:col-span-1">協力会社</div>
                            <BorderDiv className="project-detail-inf-row-content-sp-2 max-md:min-h-[40px] max-md:col-span-4 md:col-span-3 flex !flex-col !pl-0">
                                <Controller
                                    name="cooperative_company_id"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            options={partnerOptions}
                                            showSearch
                                            {...register('cooperative_company_id', projectQuoteValidation(t).cooperative_company_id())}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            {...field}
                                            // emptyMessage={'該当なし'}
                                            style={{
                                                height: '45px !important', width: '100%', fontSize: '16px'
                                                , paddingTop: '0px', paddingBottom: '0px'
                                            }}
                                            placeholder=""
                                        />
                                        // <SelectSearch
                                        //     options={[
                                        //         ...OPTION_NONE, ...partnerOptions]}
                                        //     search
                                        //     filterOptions={handleFilter}
                                        //     {...field}
                                        //     emptyMessage={'該当なし'}
                                        //     placeholder=""
                                        //     disabled={isDisable}
                                        // />
                                    )}
                                />
                                {errors.cooperative_company_id && (
                                    <span className="error w-full !text-left pl-[10px]">
                                        {errors.cooperative_company_id.message?.toString()}
                                    </span>
                                )}
                            </BorderDiv>
                        </div>
                        <div className="project-detail-inf-row-sp grid md:grid-cols-4">
                            <div className="project-detail-inf-row-title-sp-3 max-md:h-[40px] !text-[16px] max-md:col-span-4 md:col-span-1">案件管理者 </div>
                            <BorderDiv className="project-detail-inf-row-content-sp-2 max-md:min-h-[40px] max-md:col-span-4 md:col-span-3 flex !flex-col !pl-0">
                                <Controller
                                    name="worker_id"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            options={staffOptions}
                                            showSearch
                                            {...register('worker_id', projectQuoteValidation(t).worker())}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            {...field}
                                            // emptyMessage={'該当なし'}
                                            style={{ height: '45px !important', width: '100%', paddingTop: '0px', paddingBottom: '0px', fontSize: '16px' }}
                                            placeholder=""
                                        />
                                    )}
                                />
                                {errors.worker_id && (
                                    <span className="error w-full !text-left pl-[10px]">
                                        {errors.worker_id.message?.toString()}
                                    </span>
                                )}
                            </BorderDiv>
                        </div>
                        <div className="project-detail-inf-row-sp grid md:grid-cols-4">
                            <div className="project-detail-inf-row-title-sp-3 max-md:h-[40px] !text-[16px] max-md:col-span-4 md:col-span-1">契約金額</div>
                            <BorderDiv className="project-detail-inf-row-content-sp-2 max-md:min-h-[40px] max-md:col-span-4 md:col-span-3 justify-end items-end text-right">
                                <span className='mx-[5px] text-right text-[16px]' >{formatCurrency(getValues('total_estimate_after_tax'))}</span>
                            </BorderDiv>
                        </div>
                        <div className="project-detail-inf-row-sp grid md:grid-cols-4">
                            <div className="project-detail-inf-row-title-sp-3 max-md:h-[0px] !text-[16px] max-md:col-span-4 md:col-span-1"></div>
                            <BorderDiv className="project-detail-inf-row-content-sp-2 max-md:h-[0px] max-md:col-span-4 md:col-span-3">
                            </BorderDiv>
                        </div>
                    </div>
                    <div
                        className="text-lg flex flex-row flex-wrap gap-[10px] mt-[30px]"
                    >
                        <button
                            className="modal-create-user-button1 !text-[#000000D9] d-flex flex-row !rounded-[2px] !w-[146px]"
                            type="button"
                            onClick={() => navigate(`/quote/project/${id}`)}
                        >
                            {screenType === TYPE_OF_SCREEN.detail ? '戻る' : 'キャンセル'}
                        </button>
                        {screenType !== TYPE_OF_SCREEN.detail && <button
                            className="modal-create-user-button2 d-flex flex-row !rounded-[2px] !w-[146px]"
                            type="submit"
                        >
                            保存
                        </button>}
                    </div>
                </form>
            </div>
        </QuoteFormStyles>
    );
};

const BorderDiv = styled.div({
    border: '1px solid #F0F0F0',
    fontSize: '16px'
})

const ProjectForm: FC<ReceivedProps> = (props) => {
    return <ProjectFormLayout {...useProjectForm(props)} />;
};

export default ProjectForm;
