import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import PencilIcon from 'assets/icons/PencilSimpleLine.svg';
import RedTrash from 'assets/icons/Trash.svg';
import { Box, TextField } from '@mui/material';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { clsx } from 'clsx';
import { useModal } from 'react-hooks-use-modal';
import { CloseOutlined } from '@mui/icons-material';
import ExclamationIcon from 'assets/icons/exclamation.svg';
import PlusBlue from 'assets/icons/plus-blue.svg';
import { alertError, alertSuccess } from 'utils/helper/appHelper';
import customerService from 'services/customerService';
import ModalCustomer from './components';
import { Tooltip } from 'antd';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#E8E8E8',
        color: '#1C1E21',
        fontWeight: 600,
        padding: '12px 14px',
    },
    [`&.${tableCellClasses.body}`]: {
        fontFamily: 'Zen Kaku Gothic Antique',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        color: '#1C1E21',
        padding: '8px 14px',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const LeftOutlinedCustom = styled(LeftOutlined)``;
interface ICustomer {
    id: number | string;
    first_name: string;
    last_name: string;
    first_name_katakana: string;
    last_name_katakana: string;
    zipcode: string;
    address: string;
    phone_number: string;
}
interface IResponse {
    customers: ICustomer[];
    meta: {
        total_count: number;
    };
}
interface ISearchValues {
    filter_value: string;
}

const initialSearchValues: ISearchValues = {
    filter_value: '',
};

const Customers: React.FC = () => {
    const [customers, setCustomers] = useState<ICustomer[]>([]);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [statusPrev, setStatusPrev] = useState(false);
    const [statusNext, setStatusNext] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState<ICustomer | null>(
        null,
    );
    const [openModal, setOpenModal] = useState(false);
    const PER_PAGE = 10;
    const [ModalDelete, openDelete, closeDelete] = useModal('root', {
        preventScroll: true,
    });
    const [searchValues, setSearchValues] =
        useState<ISearchValues>(initialSearchValues);
    const [submitValues, setSubmitValues] =
        useState<ISearchValues>(initialSearchValues);
    const startIndex = (page - 1) * PER_PAGE;
    const setPagePrev = () => {
        if (page > 1) {
            setPage(page - 1);
            setStatusNext(false);
            setStatusPrev(true);
        }
    };
    const setPageNext = () => {
        if (page < totalPage) {
            setPage(page + 1);
            setStatusNext(true);
            setStatusPrev(false);
        }
    };

    const fetchCustomers = async () => {
        try {
            const response = await customerService.getListCustomer({
                page: page,
                per_page: PER_PAGE,
                filter_value: submitValues.filter_value,
            });
            const data = response as IResponse;
            if (data) {
                const totalCount = data?.meta?.total_count;
                const totalInteger = Math.floor(totalCount / PER_PAGE);
                const totalSurplus = totalCount % PER_PAGE;
                const totalPageCustom =
                    totalSurplus >= 1 ? totalInteger + 1 : totalInteger;
                setTotalPage(totalPageCustom);
                setCustomers(data?.customers);
            }
        } catch (error) {
            alertError(error?.response?.data?.error || '');
        }
    };

    const handleSearchCustomer = () => {
        setPage(1);
        setSubmitValues((prev) => ({
            ...prev,
            filter_value: searchValues.filter_value,
        }));
    };

    const handleDeleteCustomer = async () => {
        if (!selectedCustomer) {
            return;
        }
        try {
            const response = await customerService.deleteCustomer(
                selectedCustomer.id,
            );
            if (response) {
                fetchCustomers();
                alertSuccess(`削除しました。`);
            }
        } catch (error) {
            alertError(error?.response?.data?.error || '');
        } finally {
            closeDelete();
            setSelectedCustomer(null);
        }
    };

    useEffect(() => {
        fetchCustomers();
    }, [page, submitValues]);

    return (
        <>
            <div className="container-worker d-flex flex-row" style={{ minHeight: '100vh' }}>
                <div className="worker-content d-flex flex-column">
                    <div className="flex justify-end mb-[26px]">
                        <button
                            className="modal-create-user-button1 !h-[36px] !rounded-[3px] d-flex flex-row w-[110px] gap-[5px] px-0 py-1"
                            style={{
                                width: '150px',
                                borderRadius: '0px',
                                border: '1px solid #215493',
                                color: '#215493',
                            }}
                            type="button"
                            onClick={() => setOpenModal(true)}
                        >
                            <img src={PlusBlue} alt="" />
                            新規作成
                        </button>
                    </div>
                    <div className="filterSection flex max-md:flex-col max-md:items-center gap-y-4 md:flex-row md:items-end mb-3">
                        <div className="max-md:w-[100%] md:w-[30%]">
                            <p className="!mb-[6px] text-[14px] font-medium">
                                キーワード
                            </p>
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    '.input-search': {
                                        border: '1px solid #cdd1d5',
                                        borderTopLeftRadius: '2px',
                                        borderBottomLeftRadius: '2px',
                                        marginBottom: '14.5px',
                                        paddingLeft: '14px',
                                    },
                                    '.MuiTextField-root': {
                                        border: '1px solid #cdd1d5',
                                        width: '100%',
                                        borderTopLeftRadius: '2px',
                                        borderBottomLeftRadius: '2px',
                                        paddingLeft: '14px',
                                    },
                                    '.MuiInputBase-input': {
                                        height: '35px',
                                        padding: '0px !important',
                                    },
                                    '.MuiButtonBase-root': {
                                        minWidth: '0px',
                                    },
                                }}
                            >
                                <TextField
                                    autoComplete="off"
                                    type="text"
                                    value={searchValues.filter_value}
                                    onChange={(evt) =>
                                        setSearchValues((prev) => ({
                                            ...prev,
                                            filter_value: evt.target.value,
                                        }))
                                    }
                                />
                            </Box>
                        </div>
                        <button
                            onClick={handleSearchCustomer}
                            className="modal-create-user-button2 max-md:w-[100%] md:!w-[10%] !min-w-[70px] !h-[35px] !rounded-[3px] ml-3"
                        >
                            検索
                        </button>
                    </div>
                    <div className="table-workers d-flex flex-column">
                        <div className="table-content d-flex flex-column">
                            <TableContainer>
                                <Table
                                    sx={{ minWidth: 900 }}
                                    aria-label="customized table "
                                >
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell
                                                className="text-overflow1 !text-left"
                                                style={{ width: '5%' }}
                                            >
                                                <div>番号</div>
                                            </StyledTableCell>

                                            <StyledTableCell
                                                className="text-overflow1 !text-left"
                                                style={{ width: '16%' }}
                                            >
                                                <div>顧客名</div>
                                            </StyledTableCell>

                                            <StyledTableCell
                                                className="text-overflow1 !text-left"
                                                style={{ width: '13%' }}
                                            >
                                                <div>電話番号</div>
                                            </StyledTableCell>

                                            <StyledTableCell
                                                className="text-overflow1 !text-left"
                                                style={{ width: '12%' }}
                                            >
                                                <div>郵便番号</div>
                                            </StyledTableCell>

                                            <StyledTableCell
                                                className="text-overflow1 !text-left"
                                                style={{ width: '30%' }}
                                            >
                                                <div>住所</div>
                                            </StyledTableCell>
                                            <StyledTableCell
                                                className="text-overflow1 !text-center"
                                                style={{ width: '8%' }}
                                            >
                                                <div>操作</div>
                                            </StyledTableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {customers.length > 0 &&
                                            customers.map((row, idx) => {
                                                return (
                                                    <StyledTableRow
                                                        key={row.id}
                                                    >
                                                        <StyledTableCell
                                                            className="!text-left"
                                                            component="th"
                                                            scope="row"
                                                            style={{
                                                                width: '5%',
                                                            }}
                                                        >
                                                            {startIndex +
                                                                idx +
                                                                1}
                                                        </StyledTableCell>

                                                        <StyledTableCell
                                                            className="!text-left"
                                                            style={{
                                                                width: '16%',
                                                            }}
                                                        >
                                                            <div className="line-clamp-1 text-overflow1 whitespace-nowrap text-ellipsis overflow-hidden">{`${row.last_name}${row.first_name}`}</div>
                                                        </StyledTableCell>

                                                        <StyledTableCell
                                                            className="!text-left text-overflow1"
                                                            style={{
                                                                width: '13%',
                                                            }}
                                                        >
                                                            <div>
                                                                {
                                                                    row.phone_number
                                                                }
                                                            </div>
                                                        </StyledTableCell>

                                                        <StyledTableCell
                                                            className="!text-left"
                                                            style={{
                                                                width: '12%',
                                                            }}
                                                        >
                                                            <div>
                                                                {row.zipcode}
                                                            </div>
                                                        </StyledTableCell>

                                                        <StyledTableCell
                                                            className="!text-left"
                                                            style={{
                                                                width: '30%',
                                                            }}
                                                        >
                                                            <div className="line-clamp-1 text-overflow1 text-ellipsis overflow-hidden">
                                                                {row.address}
                                                            </div>
                                                        </StyledTableCell>

                                                        <StyledTableCell
                                                            align="center"
                                                            style={{
                                                                width: '8%',
                                                            }}
                                                        >
                                                            <div className="flex justify-end items-center">
                                                                <Box
                                                                    sx={{
                                                                        cursor: 'pointer',
                                                                        width: 24,
                                                                        height: 24,
                                                                        margin: '0px 8px 0px 4px',
                                                                    }}
                                                                    onClick={() => {
                                                                        setOpenModal(
                                                                            true,
                                                                        ),
                                                                            setSelectedCustomer(
                                                                                row,
                                                                            );
                                                                    }}
                                                                >
                                                                    <Tooltip title="編集">
                                                                        <img
                                                                            src={
                                                                                PencilIcon
                                                                            }
                                                                            alt="pencil icon"
                                                                        />
                                                                    </Tooltip>
                                                                </Box>
                                                                <Box
                                                                    sx={{
                                                                        cursor: 'pointer',
                                                                        width: 24,
                                                                        height: 24,
                                                                        margin: '0px 4px',
                                                                    }}
                                                                    onClick={() => {
                                                                        openDelete();
                                                                        setSelectedCustomer(
                                                                            row,
                                                                        );
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={
                                                                            RedTrash
                                                                        }
                                                                        alt="red trash icon"
                                                                    />
                                                                </Box>
                                                            </div>
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                                {customers.length === 0 && (
                                    <div className="text-center p-[10px] w-full">
                                        データがありません。
                                    </div>
                                )}
                            </TableContainer>
                        </div>
                    </div>

                    <div className="flex items-baseline gap-[50px] justify-end mt-[20px] pr-[18px]">
                        <p className="font-normal text-[14px] text-[#666666]">
                            ページ {page} / {totalPage}
                        </p>
                        <div className="flex gap-[25px]">
                            <LeftOutlinedCustom
                                style={{ color: '#666666', fontSize: 12 }}
                                className={clsx(
                                    'cursor-pointer !font-semibold',
                                    {
                                        '!text-[black]':
                                            statusPrev && page !== 1,
                                        '!text-[#D3D3D3]': page === 1,
                                    },
                                )}
                                onClick={setPagePrev}
                                disabled
                            />

                            <RightOutlined
                                style={{ color: '#666666', fontSize: 12 }}
                                className={clsx(
                                    'cursor-pointer !font-semibold',
                                    {
                                        '!text-[black] inline-flex':
                                            statusNext && page !== totalPage,
                                        '!text-[#D3D3D3]': page === totalPage,
                                    },
                                )}
                                onClick={setPageNext}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <ModalDelete>
                <div
                    className="d-flex flex-column justify-center !bg-[#ffffff] rounded-[10px] px-[20px]"
                    style={{ width: '449px', height: '285px' }}
                >
                    <div className="flex justify-end">
                        <CloseOutlined
                            onClick={() => {
                                setSelectedCustomer(null);
                                closeDelete();
                            }}
                        />
                    </div>
                    <div className="flex flex-col justify-center items-center pt-[15px] pb-[10px]">
                        <img
                            src={ExclamationIcon}
                            className="w-[80px] h-[80px]"
                            alt="exclamation icon"
                        />
                        <div className="text-[#215493] font-medium text-[16px] pt-[5px]">
                            警告
                        </div>
                        <div className="text-[#3A3B3C] font-normal text-[13px] pt-[5px]">
                            本当に削除しますか?
                        </div>
                        <div
                            className="flex gap-[10px]"
                            style={{ marginTop: '40px' }}
                        >
                            <button
                                className="modal-create-user-button1 !text-[#3A3B3C] d-flex flex-row !w-[147px] !h-[36px] !rounded-[3px]"
                                onClick={() => {
                                    setSelectedCustomer(null);
                                    closeDelete();
                                }}
                            >
                                キャンセル
                            </button>
                            <button
                                onClick={handleDeleteCustomer}
                                className="modal-create-user-button2 d-flex flex-row !w-[147px] !h-[36px] !rounded-[3px]"
                            >
                                削除
                            </button>
                        </div>
                    </div>
                </div>
            </ModalDelete>
            <ModalCustomer
                open={openModal}
                onClose={() => {
                    setOpenModal(false), setSelectedCustomer(null);
                }}
                id={selectedCustomer?.id || ''}
                onSuccess={fetchCustomers}
            />
        </>
    );
};

export default Customers;
