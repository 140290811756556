import { FC, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { Box, TextField, InputAdornment, Button } from '@mui/material';
import { InvoiceStatusEnum, QuoteStatusEnum, QuoteTypeEnum } from 'utils/enums';
import RedTrash from 'assets/icons/Trash.svg';
import SelectSearch, { SelectSearchOption } from 'react-select-search';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { clsx } from 'clsx';
import moment from 'moment';
// import useInvoiceList, { Props, ReceivedProps } from './hook';
import { ConfigProvider, DatePicker, Input } from 'antd';
import invoiceValidation from 'utils/validation/registers/invoice';
import PlusIcon from 'assets/icons/white-plus.svg'
import jaJP from 'antd/es/locale/ja_JP';
import NumberFormat, { NumberFormatBase, NumericFormat, useNumericFormat } from 'react-number-format';
import InvoicePdf from './invoicePdf';
import InvoiceQuotePdf from './invoiceQuotePdf';
import UploadWhite from 'assets/icons/upload-white.svg'
import UploadGrey from 'assets/icons/upload-grey.svg'
import UpArrow from 'assets/icons/up-arrow.svg'
import { formatCurrency } from 'utils/helper/currency';
import pdfFileIcon from 'assets/icons/pdf-file.svg';
import { validateZipcode } from 'utils/helper/appHelper';
import { validZipCode } from 'utils/validation/registers/validator';
import { useTranslation } from 'react-i18next';
import useInvoiceHome, { Props, ReceivedProps } from 'pages/InvoiceHome/hook';
import { Controller, useFormContext } from 'react-hook-form';
import { MAX_LENGTH } from 'pages/project/projectPlan/projectTabs/constant';

const statusOptions = Object.values(QuoteStatusEnum).map((item) => ({
    value: item.value,
    name: item.title,
}));

statusOptions.unshift({
    value: 'all',
    name: 'すべて'
})

const LeftOutlinedCustom = styled(LeftOutlined)``;

const japanNumeral: any = ['０', '１', '２', '３', '４', '５', '６', '７', '８', '９'];

function CustomNumeralNumericFormat(props) {
    const { format, removeFormatting, isCharacterSame, ...rest }: any = useNumericFormat(props);

    const _format = (val) => {
        const _val = format(val);
        return _val.replace(($1) => japanNumeral[Number($1)], /\d/g);
    };

    const _removeFormatting = (val) => {
        const _val = val.replace(new RegExp(japanNumeral.join('|'), 'g'), ($1) =>
            japanNumeral.indexOf($1),
        );

        return removeFormatting(_val);
    };

    const _isCharacterSame = (compareMeta) => {
        const isCharSame = isCharacterSame(compareMeta);
        const { formattedValue, currentValue, formattedValueIndex, currentValueIndex } = compareMeta;
        const curChar = currentValue[currentValueIndex];
        const newChar = formattedValue[formattedValueIndex];
        const curPersianChar = japanNumeral[Number(curChar)] ?? curChar;
        const newPersianChar = japanNumeral[Number(newChar)] ?? newChar;

        return isCharSame || curPersianChar || newPersianChar;
    };

    return (
        <NumberFormatBase
            format={_format}
            removeFormatting={_removeFormatting}
            isCharacterSame={_isCharacterSame}
            {...rest}
        />
    );
}

export function fullWidthNumConvert(fullWidthNum) {
    return fullWidthNum.replace(/[\uFF10-\uFF19]/g, function (m) {
        return String.fromCharCode(m.charCodeAt(0) - 0xfee0);
    });
}

export const parseNamePdf = (file_path: any) => {
    const arrName: any = [];
    const string: any = decodeURIComponent(file_path);
    if (string !== null && string !== undefined) {
        for (let i = string?.length - 1; i > 0; i--) {
            if (string[i] !== '/') {
                arrName.push(string[i]);
            } else {
                break;
            }
        }
    }
    return arrName
        .slice()
        .reverse()
        .toString()
        .replaceAll(',', '')
        .split('.')[0];
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#377ACD',
        color: '#fff',
        fontWeight: 600,
        padding: '6px 8px',
    },
    [`&.${tableCellClasses.body}`]: {
        fontFamily: 'Zen Kaku Gothic Antique',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        color: '#1C1E21',
        padding: '0px 8px',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const formatter = (str) => str ? str.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""

const parser = (str) => str.replaceAll(",", "")

const convertFullwidthToHalfwidth = (str) => {
    return str.replace(/[！-～]/g, (r) => {
        return String.fromCharCode(r.charCodeAt(0) - 0xFEE0)
    })
}

const sampleItem = {
    title: '',
    content: [''],
    price: [''],
    quantity: [''],
    unit: ['式'],
    budgetQuantity: [''],
    budgetPrice: [''],
    tax: ['10']
}

const InvoiceListLayout: FC<Props> = ({
    // onAddArrEstimate,
    // handleBlurEstimate,
    // onDeleteEstimate,
    invoice,
    queryObj,
    arrInvoiceEstimate,
    // onDeleteTitleEstimate,
    // onAddArrItemEstimate,
    // onHideEstimate,
    totalItemPrice,
    totalItemBudgetPrice,
    totalItemRevenue,
    totalPrice,
    totalBudgetPrice,
    totalRevenue,
    quoteDetail,
    totalFile,
    getDetail,
    setArrInvoiceEstimate,
    setTotalItemPrice,
    setTotalItemBudgetPrice,
    setTotalItemRevenue,
    setTotalPrice,
    setTotalBudgetPrice,
    setTotalRevenue
}) => {
    const navigate = useNavigate();
    const [disableInvoice, setDisableInvoice] = useState(true)
    const [disableQuote, setDisableQuote] = useState(true)
    const findZipcodeTimer = useRef<NodeJS.Timeout>();
    const { t } = useTranslation();
    const { register, getValues, setError, clearErrors, watch, control, setValue } = useFormContext();
    useEffect(() => {
        return () => {
            if (findZipcodeTimer.current) {
                clearTimeout(findZipcodeTimer.current);
            }
        };
    }, []);

    const onNavigateBack = () => {
        if (queryObj?.prev && queryObj?.prev === 'quote') {
            navigate('/quote/list')
        } else {
            navigate('/invoices')
        }
    }

    const calTotalEstimate = () => {
        let totalPriceTmp = 0
        let totalBudgetTmp = 0
        let totalRevenueTmp = 0
        let totalPriceArr: number[] = []
        let totalBudgetArr: number[] = []
        let totalRevenueArr: number[] = []
        if (getValues('titleInv')?.length > 0) {
            for (let i = 0; i < getValues('titleInv')?.length; i++) {

                let totalPriceItemTmp = 0
                let totalBudgetItemTmp = 0
                let totalRevenueItemTmp = 0
                for (let k = 0; k < getValues(`titleInv.${i}.tax`)?.length; k++) {
                    let price = getValues(`titleInv.${i}.price_tab1.${k}`) ?
                        getValues(`titleInv.${i}.price_tab1.${k}`).replace(/[¥,]/g, '') : ''
                    let budgetPrice = getValues(`titleInv.${i}.budgetPrice.${k}`) ?
                        getValues(`titleInv.${i}.budgetPrice.${k}`).replace(/,/g, '') : ''
                    let quantity = getValues(`titleInv.${i}.quantity_tab1.${k}`) ?
                        getValues(`titleInv.${i}.quantity_tab1.${k}`).replace(/,/g, '') : ''
                    let budgetQuantity = getValues(`titleInv.${i}.budgetQuantity.${k}`) ?
                        getValues(`titleInv.${i}.budgetQuantity.${k}`).replace(/,/g, '') : ''
                    totalPriceTmp += Number(price) * Number(quantity)
                    totalBudgetTmp += Number(budgetPrice) * Number(budgetQuantity)
                    totalRevenueTmp += (Number(price) * Number(quantity)) - (Number(budgetPrice) * Number(budgetQuantity))
                    totalPriceItemTmp += Number(price) * Number(quantity)
                    totalBudgetItemTmp += Number(budgetPrice) * Number(budgetQuantity)
                    totalRevenueItemTmp += (Number(price) * Number(quantity)) - (Number(budgetPrice) * Number(budgetQuantity))

                }
                totalPriceArr.push(totalPriceItemTmp)
                totalBudgetArr.push(totalBudgetItemTmp)
                totalRevenueArr.push(totalRevenueItemTmp)

            }

            setTotalItemPrice(totalPriceArr)
            setTotalItemBudgetPrice(totalBudgetArr)
            setTotalItemRevenue(totalRevenueArr)
            setTotalPrice(totalPriceTmp)
            setValue('priceTotal', totalPriceTmp)
            setTotalBudgetPrice(totalBudgetTmp)
            setTotalRevenue(totalRevenueTmp)
        } else {
            setTotalItemPrice([])
            setTotalItemBudgetPrice([])
            setTotalItemRevenue([])
            setValue('priceTotal', 0)
            setTotalPrice(0)
            setTotalBudgetPrice(0)
            setTotalRevenue(0)
        }
    }

    const onDeleteTitleEstimate = (index) => {
        const filterArrItem = arrInvoiceEstimate.filter((item, ind) => ind !== index)
        const titleInvFilter = getValues('titleInv').filter((item, ind) => ind !== index)
        const arrTmp: any = []
        for (let i = 0; i < filterArrItem.length; i++) {
            const countTmp = arrTmp[arrTmp.length - 1] || 0
            arrTmp.push(true)
        }
        setArrInvoiceEstimate([...arrTmp])
        setValue('titleInv', [...titleInvFilter])
        calTotalEstimate()
    }

    const onDeleteEstimate = (titleInd, index) => {
        const contentFilter = getValues(`titleInv.${titleInd}.content`).filter((item, ind) => ind !== index)
        const unitFilter = getValues(`titleInv.${titleInd}.unit`).filter((item, ind) => ind !== index)
        const priceFilter = getValues(`titleInv.${titleInd}.price_tab1`).filter((item, ind) => ind !== index)
        const budgetPriceFilter = getValues(`titleInv.${titleInd}.budgetPrice`).filter((item, ind) => ind !== index)
        const budgetQuantityFilter = getValues(`titleInv.${titleInd}.budgetQuantity`).filter((item, ind) => ind !== index)
        const quantityFilter = getValues(`titleInv.${titleInd}.quantity_tab1`).filter((item, ind) => ind !== index)
        const taxFilter = getValues(`titleInv.${titleInd}.tax`).filter((item, ind) => ind !== index)
        setValue(`titleInv.${titleInd}.content`, [...contentFilter])
        setValue(`titleInv.${titleInd}.unit`, [...unitFilter])
        setValue(`titleInv.${titleInd}.quantity_tab1`, [...quantityFilter])
        setValue(`titleInv.${titleInd}.price_tab1`, [...priceFilter])
        setValue(`titleInv.${titleInd}.budgetPrice`, [...budgetPriceFilter])
        setValue(`titleInv.${titleInd}.budgetQuantity`, [...budgetQuantityFilter])
        setValue(`titleInv.${titleInd}.tax`, [...taxFilter])
        calTotalEstimate()
    }

    const handleBlurEstimate = () => {
        calTotalEstimate()
    }


    const onAddArrEstimate = () => {
        const arrInvTmp = [...arrInvoiceEstimate]
        if (arrInvTmp.length > 0) {
            arrInvTmp.push(true)
            setValue(`titleInv.${arrInvTmp.length - 1}`, sampleItem)
        } else {
            arrInvTmp.push(true)
            setValue(`titleInv.0`, sampleItem)
        }
        setArrInvoiceEstimate(arrInvTmp)
    }

    const onAddArrItemEstimate = (index: number) => {
        const arrInvTmp = [...arrInvoiceEstimate]
        const varUnit = watch(`titleInv.${index}.unit`)
        if (varUnit?.length > 0) {
            arrInvTmp.push(true)
            setValue(`titleInv.${index}.content.${varUnit?.length}`, '')
            setValue(`titleInv.${index}.price_tab1.${varUnit?.length}`, '')
            setValue(`titleInv.${index}.quantity_tab1.${varUnit?.length}`, '')
            setValue(`titleInv.${index}.tax.${varUnit?.length}`, '10')
            setValue(`titleInv.${index}.unit.${varUnit?.length}`, '式')
            setValue(`titleInv.${index}.budgetPrice.${varUnit?.length}`, 0)
            setValue(`titleInv.${index}.budgetQuantity.${varUnit?.length}`, 0)

        } else {
            setValue(`titleInv.${index}.unit.0`, '式')
            setValue(`titleInv.${index}.content.0`, '')
            setValue(`titleInv.${index}.price_tab1.0`, '')
            setValue(`titleInv.${index}.quantity_tab1.0`, '')
            setValue(`titleInv.${index}.tax.0`, '10')
            setValue(`titleInv.${index}.budgetPrice.0`, 0)
            setValue(`titleInv.${index}.budgetQuantity.0`, 0)
        }
    }

    const onHideEstimate = (index) => {
        const arrTmp = [...arrInvoiceEstimate]
        arrTmp[index] = !arrTmp[index]
        setArrInvoiceEstimate(arrTmp)
    }

    useEffect(() => {
        if (invoice?.status) {
            if ((invoice.status === 'init' || invoice.status === 'estimate_sent_and_waiting_approval' ||
                invoice.status === 'estimate_rejected' || invoice.status === 'invoice_accepted_done'
                || invoice.status === 'waiting_for_payment' || invoice.status === 'estimate_approved')) {
                setDisableInvoice(true)
            } else {
                setDisableInvoice(false)
            }

            if (invoice.status === 'estimate_approved' || invoice.status === 'invoice_accepted_done'
                || invoice.status === 'invoice_sent_and_waiting_approval' || invoice.status === 'paid'
                || invoice.status === 'invoice_rejected'
            ) {
                setDisableQuote(true)
            } else {
                setDisableQuote(false)
            }
        } else {
            setDisableQuote(true)
            setDisableInvoice(true)
        }
    }, [invoice?.status])
    return (
        <ConfigProvider locale={jaJP}>
            <div className="container-worker d-flex flex-row !px-[0px] !pt-[0px] !rounded-tr-none !rounded-tl-none">

                <div className="d-flex flex flex-column bg-[#fff] p-[28px] !pt-0 !w-full">
                    <div className='flex flex-row justify-end' >
                        {invoice?.status && <div
                            className="text-overflow1"
                            style={{
                                whiteSpace:
                                    'nowrap',
                                color: InvoiceStatusEnum[
                                    invoice?.status
                                ]?.textColor,
                                backgroundColor:
                                    InvoiceStatusEnum[
                                        invoice?.status
                                    ]?.bgColor,
                                borderRadius:
                                    '15px',
                                padding:
                                    '4px 9px',
                            }}
                        >
                            {
                                InvoiceStatusEnum[
                                    invoice?.status
                                ]?.title
                            }
                        </div>}

                    </div>
                    <div className='max-md:overflow-x-scroll' >
                        <div className="table-workers d-flex flex-column mt-[15px] max-md:min-w-[1100px]">
                            <div
                                // className="table-content d-flex flex-column"
                                style={{ border: '1px solid #F2F2F2' }}>

                                <TableContainer table-layout={'auto'}>
                                    <Table
                                        sx={{ minWidth: 900 }}
                                        aria-label="customized table"
                                    >
                                        <TableHead style={{ background: '#377ACD', color: '#fff' }}>
                                            <TableRow>
                                                <StyledTableCell
                                                    className="text-overflow1"
                                                    style={{ width: '5%', whiteSpace: 'nowrap' }}
                                                >
                                                    <div>番号</div>
                                                </StyledTableCell>

                                                <StyledTableCell
                                                    className="text-overflow1"
                                                    style={{ width: '25%' }}
                                                >
                                                    <div>内容</div>
                                                </StyledTableCell>

                                                <StyledTableCell
                                                    className="text-overflow1"
                                                    style={{ width: '8%' }}
                                                >
                                                    <div>数量</div>
                                                </StyledTableCell>

                                                <StyledTableCell
                                                    className="text-overflow1"
                                                    style={{ width: '8%' }}
                                                >
                                                    <div>単位</div>
                                                </StyledTableCell>

                                                <StyledTableCell
                                                    className="text-overflow1"
                                                    style={{ width: '11%' }}
                                                >
                                                    <div>単価</div>
                                                </StyledTableCell>

                                                <StyledTableCell
                                                    className="text-overflow1"
                                                    style={{ width: '11%' }}
                                                >
                                                    <div>金額</div>
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    className="text-overflow1"
                                                    style={{ width: '11%' }}
                                                >
                                                    <div style={{ whiteSpace: 'nowrap', textAlign: 'center' }} >仕入値予算(税抜)</div>
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    className="text-overflow1"
                                                    style={{ width: '11%', textAlign: 'center' }}
                                                >
                                                    <div style={{ whiteSpace: 'nowrap', textAlign: 'center' }} >粗利</div>
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    className="text-overflow1"
                                                    style={{ width: '5%', padding: '0px' }}
                                                >
                                                    <div style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>操作</div>
                                                </StyledTableCell>
                                            </TableRow>
                                        </TableHead>

                                    </Table>
                                </TableContainer>
                                {arrInvoiceEstimate.map((row, index) => {
                                    return (
                                        <div >
                                            <div className="flex flex-row justify-between py-[4px]" >
                                                <div className='flex flex-row w-[100%]' >
                                                    <div className='w-[5.88%] px-[8px] flex flex-row gap-[16px] text-[16px]' >
                                                        {index + 1}
                                                        <img src={UpArrow} height={5} width={16} className='cursor-pointer'
                                                            style={{
                                                                transform: `${row ? 'rotate(0)' : 'rotate(180deg)'}`,
                                                                transition: '0.5s transform'
                                                            }}
                                                            alt="" onClick={() => onHideEstimate(index)} />
                                                    </div>
                                                    <div className='w-[27.3%] pl-[4px] pr-[8px] text-[16px]' >
                                                        <Controller
                                                            name={`titleInv.${index}.title`}
                                                            control={control}
                                                            render={({ field }) => (
                                                                <StyledDefaultInput
                                                                    {...register(
                                                                        `titleInv.${index}.title`
                                                                    )}
                                                                    {...field}
                                                                    placeholder='項目は必須です'
                                                                    maxLength={MAX_LENGTH} />

                                                            )}
                                                        />
                                                    </div>
                                                    <div className='w-[8%] flex flex-row justify-center' >
                                                        <Button className='w-[40px] !h-[30px] text-white bg-[#215493]'
                                                            style={{ borderRadius: '4px', background: '#215493' }}
                                                            onClick={() => onAddArrItemEstimate(index)}
                                                        >
                                                            <img src={PlusIcon} alt='plus' height={18} width={18} /></Button>
                                                    </div>

                                                </div>
                                                <div
                                                    className="flex justify-center w-[5%] pr-[8px] items-center">
                                                    <Box
                                                        sx={{
                                                            cursor: 'pointer',
                                                            width: 24,
                                                            height: 24,
                                                            margin: '0px',
                                                            marginLeft: '6px'
                                                        }}
                                                        onClick={() => {
                                                            onDeleteTitleEstimate(index)
                                                        }}
                                                    >
                                                        <img
                                                            src={
                                                                RedTrash
                                                            }
                                                            alt="red trash icon"
                                                        />
                                                    </Box>
                                                </div>
                                            </div>

                                            {row && <TableContainer table-layout={'auto'}>
                                                <Table
                                                    sx={{ minWidth: 900 }}
                                                    aria-label="customized table"
                                                >

                                                    <TableBody>
                                                        {watch(`titleInv.${index}.tax`) && watch(`titleInv.${index}.tax`).map((row, ind) => {
                                                            if (ind < watch(`titleInv.${index}.tax`).length) {
                                                                let price = getValues(`titleInv.${index}.price_tab1.${ind}`) || ''
                                                                let quantity = getValues(`titleInv.${index}.quantity_tab1.${ind}`) || ''
                                                                let budgetPrice = getValues(`titleInv.${index}.budgetPrice.${ind}`) || ''
                                                                let budgetQuantity = getValues(`titleInv.${index}.budgetQuantity.${ind}`) || ''
                                                                if (String(budgetQuantity).includes(',')) {
                                                                    budgetQuantity = budgetQuantity.replace(/,/g, '')
                                                                }
                                                                if (String(price).includes(',') || String(price).includes('¥')) {
                                                                    price = price.replace(/[¥,]/g, '')
                                                                }
                                                                if (String(budgetPrice).includes(',')) {
                                                                    budgetPrice = budgetPrice.replace(/,/g, '')
                                                                }
                                                                if (String(quantity).includes(',')) {
                                                                    quantity = quantity.replace(/,/g, '')
                                                                }
                                                                return (
                                                                    <StyledTableRow key={row} style={{ borderTop: `${ind === 0 && '1px solid #F2F2F2'}` }} >
                                                                        <StyledTableCell
                                                                            className="!text-left"
                                                                            component="th"
                                                                            scope="row"
                                                                            style={{ width: '5%' }}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    display: 'flex',
                                                                                    alignItems:
                                                                                        'center',
                                                                                }}
                                                                            />
                                                                        </StyledTableCell>

                                                                        <StyledTableCell
                                                                            className="!text-left"
                                                                            style={{ width: '25%' }}
                                                                        >
                                                                            <Controller
                                                                                name={`titleInv.${index}.content.${ind}`}
                                                                                control={control}
                                                                                render={({ field }) => (
                                                                                    <StyledDefaultInput
                                                                                        {...register(
                                                                                            `titleInv.${index}.content.${ind}`
                                                                                        )}
                                                                                        {...field}
                                                                                        placeholder='項目は必須です'
                                                                                        maxLength={MAX_LENGTH} />

                                                                                )}
                                                                            />

                                                                        </StyledTableCell>

                                                                        <StyledTableCell
                                                                            className="!text-left"
                                                                            style={{ width: '8%' }}
                                                                        >
                                                                            <div>
                                                                                <Controller
                                                                                    name={`titleInv.${index}.quantity_tab1.${ind}`}
                                                                                    control={control}
                                                                                    render={({ field }) => (

                                                                                        <StyledDefaultInput
                                                                                            className='text-center'
                                                                                            {...register(
                                                                                                `titleInv.${index}.quantity_tab1.${ind}`
                                                                                            )} onBlur={(evt) => {
                                                                                                let stringWithoutCommas = evt.target.value.replace(/,/g, '')
                                                                                                setValue(`titleInv.${index}.quantity_tab1.${ind}`, Number(fullWidthNumConvert(stringWithoutCommas)).toLocaleString('en-US'))
                                                                                                handleBlurEstimate()
                                                                                            }}
                                                                                            value={field.value}
                                                                                            onChange={(evt) => {
                                                                                                let stringWithoutCommas = evt.target.value.replace(/,/g, '');
                                                                                                if (stringWithoutCommas.match("^[0-9０-９]+$") || stringWithoutCommas === '') {
                                                                                                    const newVal = fullWidthNumConvert(stringWithoutCommas)
                                                                                                    field.onChange(stringWithoutCommas);
                                                                                                    setValue(`titleInv.${index}.quantity_tab1.${ind}`, stringWithoutCommas)
                                                                                                }
                                                                                            }}
                                                                                            placeholder='数量は必須です'
                                                                                        />
                                                                                    )}
                                                                                />
                                                                            </div>
                                                                        </StyledTableCell>

                                                                        <StyledTableCell
                                                                            className="!text-left"
                                                                            style={{ width: '8%' }}
                                                                        >
                                                                            <Controller
                                                                                name={`titleInv.${index}.unit.${ind}`}
                                                                                control={control}
                                                                                render={({ field }) => (
                                                                                    <StyledDefaultInput maxLength={3}
                                                                                        style={{ textAlign: 'center' }}
                                                                                        {...register(
                                                                                            `titleInv.${index}.unit.${ind}`
                                                                                        )} {...field} />

                                                                                )}
                                                                            />
                                                                        </StyledTableCell>

                                                                        <StyledTableCell
                                                                            align="center"
                                                                            style={{ width: '11%' }}
                                                                        >
                                                                            <Controller
                                                                                name={`titleInv.${index}.price_tab1.${ind}`}
                                                                                control={control}
                                                                                render={({ field }) => (
                                                                                    <StyledDefaultInput
                                                                                        className='text-right'
                                                                                        // prefix="¥"
                                                                                        {...register(
                                                                                            `titleInv.${index}.price_tab1.${ind}`
                                                                                        )} onBlur={(evt) => {
                                                                                            let stringWithoutCommas = evt.target.value.replace(/[¥,]/g, '')
                                                                                            setValue(`titleInv.${index}.price_tab1.${ind}`, formatCurrency(Number(fullWidthNumConvert(stringWithoutCommas))))
                                                                                            handleBlurEstimate()
                                                                                        }}
                                                                                        value={field.value}
                                                                                        onChange={(evt) => {
                                                                                            let stringWithoutCommas = evt.target.value.replace(/[¥,]/g, '');
                                                                                            if (stringWithoutCommas.match("^[0-9０-９]+$") || stringWithoutCommas === '') {
                                                                                                const newVal = fullWidthNumConvert(stringWithoutCommas)
                                                                                                field.onChange(stringWithoutCommas);
                                                                                                setValue(`titleInv.${index}.price_tab1.${ind}`, stringWithoutCommas)
                                                                                            }
                                                                                        }}
                                                                                        placeholder='単価は必須です'
                                                                                    />
                                                                                )}
                                                                            />

                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                            align="center"
                                                                            style={{ width: '11%' }}
                                                                        >
                                                                            <NumericFormat
                                                                                customInput={StyledDefaultInput}
                                                                                thousandSeparator=","
                                                                                decimalSeparator="."
                                                                                prefix={'￥'}
                                                                                name='amount_money'
                                                                                className="text-[#666666]"
                                                                                style={{ textAlign: 'right', background: '#fff', color: 'rgba(0, 0, 0, 0.85)' }}
                                                                                value={(Number(price) * Number(quantity)) || '0'}
                                                                                disabled
                                                                            />
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                            align="center"
                                                                            style={{ width: '11%' }}
                                                                        >
                                                                            <NumericFormat
                                                                                customInput={StyledDefaultInput}
                                                                                thousandSeparator=","
                                                                                decimalSeparator="."
                                                                                prefix={'￥'}
                                                                                name='amount_money'
                                                                                className="text-[#666666]"
                                                                                style={{ textAlign: 'right', background: '#fff', color: 'rgba(0, 0, 0, 0.85)' }}
                                                                                value={(Number(budgetPrice) * Number(budgetQuantity)) || '0'}
                                                                                disabled
                                                                            />
                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                            align="center"
                                                                            style={{ width: '11%' }}
                                                                        >
                                                                            <NumericFormat
                                                                                customInput={StyledDefaultInput}
                                                                                thousandSeparator=","
                                                                                decimalSeparator="."
                                                                                prefix={'￥'}
                                                                                disabled
                                                                                className="text-[#666666]"
                                                                                name='amount_money_tax'
                                                                                style={{ textAlign: 'right', background: '#fff', color: 'rgba(0, 0, 0, 0.85)' }}
                                                                                value={((Number(price) * Number(quantity)) - (Number(budgetPrice) * Number(budgetQuantity))) || '0'}
                                                                            />

                                                                        </StyledTableCell>
                                                                        <StyledTableCell
                                                                            align="center"
                                                                            style={{ width: '5%', paddingLeft: '0px' }}
                                                                        >
                                                                            <div style={{ height: '40px' }}
                                                                                className="flex justify-center items-center">
                                                                                <Box
                                                                                    sx={{
                                                                                        cursor: 'pointer',
                                                                                        width: 24,
                                                                                        height: 24,
                                                                                        margin: '0px',
                                                                                        marginLeft: '12px'
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        onDeleteEstimate(index, ind)
                                                                                    }}
                                                                                >
                                                                                    <img
                                                                                        src={
                                                                                            RedTrash
                                                                                        }
                                                                                        alt="red trash icon"
                                                                                    />
                                                                                </Box>
                                                                            </div>
                                                                        </StyledTableCell>
                                                                    </StyledTableRow>
                                                                )
                                                            }
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>}
                                            <div className='flex flex-row py-[10px]' style={{ borderTop: '1px solid #F2F2F2', border: '1px solid #F2F2F2' }} >
                                                <div className='w-[6%]' />
                                                <div className='w-[26.2%]' />
                                                <div className='w-[8%]' />
                                                <div className='w-[8%]' />
                                                <div className='w-[11%]' />
                                                <div className='w-[10.6%] font-bold text-right' >
                                                    {formatCurrency(totalItemPrice[index])}
                                                </div>
                                                <div className='w-[11.6%] font-bold text-right' >
                                                    {formatCurrency(totalItemBudgetPrice[index])}
                                                </div>
                                                <div className='w-[11.6%] font-bold text-right' >
                                                    {formatCurrency(totalItemRevenue[index])}
                                                </div>
                                                <div className='w-[5%]' />
                                            </div>
                                        </div>
                                    )
                                })}

                            </div>
                            <div className='flex flex-row justify-center mt-[20px]'>
                                <Button className='w-[114px] h-[38px] text-white bg-[#215493]'
                                    style={{ borderRadius: '8px', background: '#215493', gap: '8px', marginTop: '10px' }} onClick={onAddArrEstimate} >
                                    <img src={PlusIcon} alt='plus' height={16} width={16} /> 追加</Button>

                            </div>
                        </div>
                    </div>

                    <div className='flex flex-row justify-end md:h-[103px] mt-[30px]' >
                        <div className='grid md:grid-cols-3 bg-[#E8F2FF] items-end md:h-[103px] text-[14px] max-md:min-w-full md:min-w-[60%] p-[16px]'
                            style={{ borderRadius: '8px' }} >
                            <div className='flex flex-row gap-[15px]'>
                                <p style={{ color: '#0000008C' }} >税抜見積合計金額</p>
                                <p className='text-[#000]' >{formatCurrency(totalPrice)}</p>
                            </div>
                            <div className='flex flex-row gap-[15px]'>
                                <p style={{ color: '#0000008C' }}>消費税額(10%) </p>
                                <p className='text-[#000]' >{formatCurrency(totalPrice * 10 / 100)}</p>
                            </div>
                            <div className='flex flex-row gap-[15px]'>
                                <p style={{ color: '#0000008C' }}>税込見積合計</p>
                                <p className='text-[#000]'>{formatCurrency(totalPrice + totalPrice * 10 / 100)}</p>
                            </div>
                            <div className='flex flex-row gap-[15px]'>
                                <p style={{ color: '#0000008C' }}>仕入値予算合計(税抜)</p>
                                <p className='text-[#000]'>{formatCurrency(totalBudgetPrice)}</p>
                            </div>
                            <div className='flex flex-row gap-[15px]'>
                                <p style={{ color: '#0000008C' }}> 粗利合計</p>
                                <p className='text-[#000]'>{formatCurrency(totalPrice - totalBudgetPrice)}</p>
                            </div>
                            <div className='flex flex-row gap-[15px]'>
                                <p style={{ color: '#0000008C' }}>粗利率</p>
                                <p className='text-[#000]' >{((totalRevenue / totalPrice * 100) || 0).toFixed(2)}%</p>
                            </div>
                        </div>
                    </div>

                    <div className="max-md:w-full md:w-[330px] mt-[50px]" >
                        {totalFile?.length > 0 &&
                            totalFile?.map((item: any, index) => {
                                return (
                                    <div className='!my-[10px]' >
                                        <a
                                            className="text-[#000000] my-[10px] !no-underline"
                                            download
                                        >
                                            <div className="flex bg-[#F8F8F8] rounded-xl p-2">
                                                <div className="flex justify-between w-[100%] items-center">
                                                    <a
                                                        href={item?.name}
                                                        className="text-[#000000] !no-underline flex items-center"
                                                        target="_blank"
                                                    >
                                                        <img
                                                            src={
                                                                pdfFileIcon
                                                            }
                                                            alt="pdf file icon"
                                                        />
                                                        <div className="ml-3">
                                                            <div className="text-ellipsis whitespace-nowrap overflow-hidden	max-w-[450px] ">
                                                                {parseNamePdf(
                                                                    item?.name,
                                                                )}.PDF
                                                            </div>
                                                            <div className="text-[#0000008C]">
                                                                {item?.file_path
                                                                    ? item?.size
                                                                    : Number(
                                                                        item?.size /
                                                                        1024,
                                                                    )?.toFixed(
                                                                        1,
                                                                    ) +
                                                                    'KB'}
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                )
                            })}
                    </div>
                </div>
            </div>
            <InvoicePdf invoice={invoice} quoteDetail={quoteDetail} disableInvoice={disableInvoice}
                totalItemPrice={totalItemPrice} getDetail={getDetail} />
            <InvoiceQuotePdf invoice={invoice} quoteDetail={quoteDetail} disableQuote={disableQuote}
                totalItemPrice={totalItemPrice} getDetail={getDetail} />
            {/* <div className="container-worker d-flex flex-row">
                    <div className="worker-content d-flex flex-column">

                    </div>

                </div> */}

            <div className='bg-[#fff] flex flex-row max-md:justify-center max-md:flex-wrap md:justify-between gap-y-4 max-md:px-[12px] py-[16px] px-[24px]' >
                <div className='flex flex-row' >
                    <button
                        className="modal-create-user-button2 !rounded-[2px] d-flex flex-row mx-[15px] w-[140px]"
                        style={{
                            width: '140px', borderRadius: '2px', border: '1px solid #D9D9D9',
                            background: '#215493',
                            color: '#fff',
                            // background: `${(!invoice?.status) ? '#F5F5F5' : '#215493'}`,
                            // color: `${(!invoice?.status) ? '#8C8C8C' : '#fff'}`
                        }}
                        // disabled={(!invoice?.status)}
                        type="button"
                        onClick={() =>
                            navigate(
                                `/quote/list/invoice${queryObj?.quote_id ? `?quote_id=${queryObj?.quote_id}&` : '?'}${queryObj?.invoice_id ? `invoice_id=${queryObj?.invoice_id}&` : ''}` +
                                `${queryObj?.prev ? `prev=${queryObj?.prev}&` : ''}viewQuotePdf=true`,
                            )
                        }
                    >
                        <img src={UploadWhite} alt='' /> PDF見積書
                    </button>
                    <button
                        className="modal-create-user-button2 !rounded-[2px] d-flex flex-row mx-[15px] w-[140px]"
                        style={{
                            width: '140px', borderRadius: '2px',
                            // background: `${(!invoice?.status) ? '#F5F5F5' : '#215493'}`,
                            border: '1px solid #D9D9D9', background: '#215493',
                            color: '#fff'
                            // color: `${(!invoice?.status) ? '#8C8C8C' : '#fff'}`
                        }}
                        // disabled={(!invoice?.status)}
                        type="button"
                        onClick={() =>
                            navigate(
                                `/quote/list/invoice${queryObj?.quote_id ? `?quote_id=${queryObj?.quote_id}&` : '?'}${queryObj?.invoice_id ? `invoice_id=${queryObj?.invoice_id}&` : ''}${queryObj?.prev ? `prev=${queryObj?.prev}&` : ''}viewPdf=true`,
                            )
                        }
                    >
                        <img src={UploadWhite} alt='' />PDF請求書
                    </button>

                </div>

                <div className='flex flex-row' >
                    <button
                        className="modal-create-user-button1 !rounded-[2px] d-flex flex-row w-[140px]  mx-[15px]"
                        style={{ width: '140px', borderRadius: '0px', border: '1px solid #D9D9D9', color: '#000000D9' }}
                        type="button"
                        onClick={onNavigateBack}
                    >
                        キャンセル
                    </button>

                    <button
                        className="modal-create-user-button2 !rounded-[2px] d-flex flex-row mx-[15px] w-[140px]"
                        disabled={invoice?.status === 'invoice_accepted_done'}
                        style={{
                            width: '140px', borderRadius: '0px',
                            background: '#215493',
                        }}
                        type="submit"
                    >
                        保存
                    </button>
                </div>
            </div>
        </ConfigProvider>
    );
};

const InvoiceList: FC<ReceivedProps> = (props) => {
    return <InvoiceListLayout {...useInvoiceHome(props)} />;
};

const StyledInput = styled(Input)({
    padding: '8px 12px 8px 12px',
    width: '100%'
})

const StyledDefaultInput = styled(Input)({
    padding: '8px 12px 8px 12px',
    // border: 'none',
    height: '31px',
    border: '1px solid #D9D9D9',
    width: '100%',
    borderRadius: '8px'
})

export default InvoiceList;
