import { FC, useEffect, useRef, useState } from 'react';
import { DatePicker, message, Select, Upload } from 'antd';
import { Controller } from 'react-hook-form';
import SelectSearch, { SelectSearchOption } from 'react-select-search';
import useQuoteForm, { Props, ReceivedProps } from './hook';
import { QuoteFormStyles } from './styled';
import { QuoteStatusEnum } from 'utils/enums';
import { handleFilter } from 'utils/helper/filterDropdown';
import uploadIcon from 'assets/icons/addImageFile.svg';
import warningIcon from 'assets/icons/WarningCircle.svg';
import { validateZipcode } from 'utils/helper/appHelper';
import { useTranslation } from 'react-i18next';
import { RcFile, UploadChangeParam, UploadFile } from 'antd/lib/upload';
import styled from 'styled-components';
import quoteValidation from 'utils/validation/registers/quote';
import { fullWidthNumConvert } from 'pages/invoice';
import { validZipCode } from 'utils/validation/registers/validator';
import 'react-select-search/style.css'
import { MAX_LENGTH, OPTION_NONE, TYPE_OF_SCREEN } from 'pages/project/projectPlan/projectTabs/constant';

const typeOptions: any[] = [
    { value: 'interior', name: '内装', label: '内装' },
    { value: 'exterior', name: '外装', label: '外装' },
    { value: 'water_area', name: '水回り', label: '水回り' },
    { value: 'other', name: 'その他', label: 'その他' },
];

const statusOptions = Object.values(QuoteStatusEnum).map((item) => ({
    value: item.value,
    name: item.title,
    label: item.title
}));
const QuoteFormLayout: FC<Props> = ({
    handleSubmit,
    submitData,
    control,
    dummyRequest,
    setValue,
    register,
    clearErrors,
    getValues,
    setError,
    errors,
    navigate,
    fileLength,
    isAllowUpload,
    listFile,
    setFileLength,
    setAllowUpload,
    setListFile,
    totalImageSize,
    setTotalImageSize,
    handleValidateWhitespace,
    screenType,
    partnerOptions,
    isDisable
}) => {
    const [indexError, setIndexError] = useState(0);
    const { t } = useTranslation();
    const findZipcodeTimer = useRef<NodeJS.Timeout>();
    const UPLOAD_LIMIT = 50;
    const [sizeUploadLimitError, setSizeUploadLimitError] =
        useState<boolean>(false);
    useEffect(() => {
        if (sizeUploadLimitError) {
            message.error(
                `画像の合計サイズは ${UPLOAD_LIMIT}MB を超えてはなりません`,
            );
            setSizeUploadLimitError(false);
        }
    }, [sizeUploadLimitError]);

    const wordLimit = 5000;

    const parsePostalCode = (zipCode: string, type: string) => {
        if (!zipCode) {
            if (type === 'customer') {
                setError('zipCode', {
                    type: 'required',
                    message: '郵便番号を入力してください。',
                });
            } else {
                setError('zipCodeConstruction', {
                    type: 'required',
                    message: '郵便番号を入力してください。',
                });
            }

            return;
        }

        const isZipcodeFormat = validateZipcode(zipCode);
        if (!isZipcodeFormat) {
            if (type === 'customer') {
                setError('zipCode', {
                    type: 'validate',
                    message:
                        '郵便番号は数字のみで、000-0000の形式でなければなりません。',
                });
            } else {
                setError('zipCodeConstruction', {
                    type: 'required',
                    message: '郵便番号は数字のみで、000-0000の形式でなければなりません。',
                });
            }

            return;
        }

        if (findZipcodeTimer.current) {
            clearTimeout(findZipcodeTimer.current);
        }

        let foundAddress: string;
        const postalCode = require('japan-postal-code');
        postalCode.get(zipCode, function (address) {
            foundAddress = `${address?.prefecture}${address?.city}${address?.area}`;
        });

        findZipcodeTimer.current = setTimeout(() => {
            if (foundAddress) {
                if (type === 'customer') {
                    setValue('address', foundAddress);
                    clearErrors('address');
                    clearErrors('zipCode')
                } else {
                    setValue('construction_address', foundAddress);
                    clearErrors('construction_address');
                    clearErrors('zipCodeConstruction');
                }

            } else {
                if (type === 'customer') {
                    setValue('address', '');
                    setError('zipCode', {
                        type: 'validate',
                        message: '入力された郵便番号に該当する住所が存在しません。',
                    });
                } else {
                    setValue('construction_address', '');
                    setError('zipCodeConstruction', {
                        type: 'validate',
                        message: '入力された郵便番号に該当する住所が存在しません。',
                    });
                }

            }
        }, 1000);
    };
    useEffect(() => {
        return () => {
            if (findZipcodeTimer.current) {
                clearTimeout(findZipcodeTimer.current);
            }
        };
    }, []);

    const handleChangeFile = (info: UploadChangeParam<UploadFile<any>>) => {
        const loadFile = 10;
        setIndexError(0);
        const filteredPdfAfterRedundant = info?.fileList.filter(
            (bItem) => !listFile?.some((aItem) => aItem.uid === bItem.uid),
        );

        if (info.file.status === 'done') {
            setTotalImageSize(
                (prevSize) => prevSize + (info.file.size as number),
            );
        }
        if (info.file.status === 'removed') {
            setTotalImageSize(
                (prevSize) => prevSize - (info.file.size as number),
            );
        }
        if (loadFile >= info?.fileList?.length) {
            setAllowUpload(info.fileList.length < 10);
            setValue('images', info.fileList);
            setFileLength(info?.fileList?.length);
            setListFile(info?.fileList);
        } else {
            setIndexError((index) => index + 1);
        }
        clearErrors('images');
    };

    const handleBeforeUpload = (file: RcFile, fileList: RcFile[]) => {
        const uploadingSizes = fileList.reduce((accu: number, curr: RcFile) => {
            return accu + curr.size;
        }, 0);
        setIndexError(0);
        const accumulatedImageSize = totalImageSize + uploadingSizes;
        const isImage =
            file.type === 'image/jpeg' ||
            file.type === 'image/png'
        if (!isImage) {
            message.error(
                'jpg、.jpeg、.png形式でアップロードしてください。',
            );
            return Upload.LIST_IGNORE;
        }

        const isUnderLimit = accumulatedImageSize < 1024 * 1024 * UPLOAD_LIMIT;
        if (!isUnderLimit) {
            setSizeUploadLimitError(true);
            return Upload.LIST_IGNORE;
        }

        return isImage && isUnderLimit;
    };

    const uploadButton = (
        <div className="text-[#5392F0] flex flex-col items-center">
            <img
                src={uploadIcon}
                className="w-[21px] h-[21px]"
                alt="upload image icon"
            />
            <div>写真 ({fileLength > 0 ? fileLength : 0}/10)</div>
        </div>
    );

    useEffect(() => {
        indexError === 1 &&
            message.error('ファイルアップロードは10ファイルまでです。');
    }, [indexError]);

    useEffect(() => {
        setValue('phone', '')
    }, [])
    return (
        <QuoteFormStyles className="p-[24px]">
            <div className="bg-[#fff] quote-form-container rounded-[4px] p-[12px] ">
                <form onSubmit={handleSubmit(submitData)}>
                    <div className="w-full flex flex-row flex-wrap gap-[14px]">

                        <div className="flex flex-col w-full md:grid md:grid-cols-4 gap-y-[30px] md:gap-x-[14px]">
                            <div className='text-[18px] font-bold col-span-4' >
                                顧客情報
                            </div>
                            <div>
                                <Controller
                                    name="last_name"
                                    rules={{ required: true }}
                                    control={control}
                                    render={({ field }) => (
                                        <div className="d-flex flex-column">
                                            <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                <span className="text-red mr-1">
                                                    *
                                                </span>
                                                姓
                                            </p>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                className="textBox rounded-[2px] border-[1px] border-[#D9D9D9] px-2"
                                                {...register(
                                                    'last_name',
                                                    quoteValidation(
                                                        t,
                                                    ).last_name(),
                                                )}
                                                onBlur={
                                                    handleValidateWhitespace
                                                }
                                                disabled={
                                                    isDisable
                                                }
                                                maxLength={MAX_LENGTH}
                                            ></input>
                                            {errors.last_name && (
                                                <span className="error text-xs tracking-tight mt-1">
                                                    {errors.last_name.message?.toString()}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                            </div>
                            <div>
                                <Controller
                                    name="first_name"
                                    rules={{ required: true }}
                                    control={control}
                                    render={({ field }) => (
                                        <div className="d-flex flex-column">
                                            <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                <span className="text-red mr-1">
                                                    *
                                                </span>
                                                名
                                            </p>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                className="textBox rounded-[2px] border-[1px] border-[#D9D9D9] px-2"
                                                {...register(
                                                    'first_name',
                                                    quoteValidation(
                                                        t,
                                                    ).first_name(),
                                                )}
                                                onBlur={
                                                    handleValidateWhitespace
                                                }
                                                disabled={
                                                    isDisable
                                                }
                                                maxLength={MAX_LENGTH}
                                            ></input>
                                            {errors.first_name && (
                                                <span className="error text-xs tracking-tight mt-1">
                                                    {errors.first_name.message?.toString()}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                            </div>
                            <div>
                                <Controller
                                    name="last_name_kana"
                                    rules={{ required: true }}
                                    control={control}
                                    render={({ field }) => (
                                        <div className="d-flex flex-column">
                                            <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                <span className="text-red mr-1">
                                                    *
                                                </span>
                                                姓（フリガナ）
                                            </p>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                className="textBox rounded-[2px] border-[1px] border-[#D9D9D9] px-2"
                                                {...register(
                                                    'last_name_kana',
                                                    quoteValidation(
                                                        t,
                                                    ).last_name_kana(),
                                                )}
                                                onBlur={
                                                    handleValidateWhitespace
                                                }
                                                disabled={
                                                    isDisable
                                                }
                                                maxLength={MAX_LENGTH}
                                            ></input>
                                            {errors.last_name_kana && (
                                                <span className="error text-xs tracking-tight mt-1">
                                                    {errors.last_name_kana.message?.toString()}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                            </div>
                            <div>
                                <Controller
                                    name="first_name_kana"
                                    rules={{ required: true }}
                                    control={control}
                                    render={({ field }) => (
                                        <div className="d-flex flex-column">
                                            <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                <span className="text-red mr-1">
                                                    *
                                                </span>
                                                名（フリガナ）
                                            </p>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                className="textBox rounded-[2px] border-[1px] border-[#D9D9D9] px-2"
                                                {...register(
                                                    'first_name_kana',
                                                    quoteValidation(
                                                        t,
                                                    ).first_name_kana(),
                                                )}
                                                onBlur={
                                                    handleValidateWhitespace
                                                }
                                                disabled={
                                                    isDisable
                                                }
                                                maxLength={MAX_LENGTH}
                                            ></input>
                                            {errors.first_name_kana && (
                                                <span className="error text-xs tracking-tight mt-1">
                                                    {errors.first_name_kana.message?.toString()}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                            </div>

                            <div className="flex flex-col w-full">
                                <Controller
                                    name="phone"
                                    rules={{ required: true }}
                                    control={control}
                                    render={({ field }) => {
                                        const { name, onChange, onBlur, ref } =
                                            register(
                                                'phone',
                                                quoteValidation(t).phone(),
                                            );
                                        return (
                                            <div className="d-flex flex-column">
                                                <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                    <span className="text-red mr-1">
                                                        *
                                                    </span>
                                                    電話番号
                                                </p>
                                                <input
                                                    maxLength={12}
                                                    {...register('phone')}
                                                    onBlur={(evt) => {
                                                        setValue(
                                                            'phone',
                                                            fullWidthNumConvert(
                                                                evt.target
                                                                    .value,
                                                            ),
                                                        );
                                                        // onBlur()
                                                    }}
                                                    disabled={
                                                        isDisable
                                                    }
                                                    className="textBox rounded-[2px] border-[1px] border-[#D9D9D9] px-2"
                                                    value={field.value}
                                                    onChange={(evt) => {
                                                        let stringWithoutCommas =
                                                            evt.target.value.replace(
                                                                /,/g,
                                                                '',
                                                            );
                                                        if (
                                                            stringWithoutCommas.match(
                                                                '^[0-9０-９]+$',
                                                            ) ||
                                                            stringWithoutCommas ===
                                                            ''
                                                        ) {
                                                            if (
                                                                stringWithoutCommas.length <=
                                                                12
                                                            ) {
                                                                const newVal =
                                                                    fullWidthNumConvert(
                                                                        stringWithoutCommas,
                                                                    );
                                                                field.onChange(
                                                                    stringWithoutCommas,
                                                                );
                                                                setValue(
                                                                    'phone',
                                                                    stringWithoutCommas,
                                                                );
                                                            }
                                                        }
                                                    }}
                                                />
                                                {errors.phone && (
                                                    <span className="error text-xs tracking-tight mt-1">
                                                        {errors.phone.message?.toString()}
                                                    </span>
                                                )}
                                            </div>
                                        );
                                    }}
                                />
                            </div>

                            <div className="flex flex-col">
                                <Controller
                                    name="zipCode"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field, fieldState }) => {
                                        const { name, onChange, onBlur, ref } =
                                            register('zipCode', {
                                                required:
                                                    '郵便番号を入力してください。',
                                                validate: {
                                                    regex: validZipCode(
                                                        'zipcode',
                                                        t,
                                                    ),
                                                },
                                            });
                                        return (
                                            <div className="d-flex flex-column">
                                                <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                    <span className="text-red mr-1">
                                                        *
                                                    </span>
                                                    郵便番号
                                                </p>
                                                <div className="flex gap-[10px]">
                                                    <input
                                                        placeholder="000-0000"
                                                        {...register('zipCode')}
                                                        className="textBox rounded-[2px] border-[1px] border-[#D9D9D9] px-2"
                                                        onBlur={(evt) => {
                                                            let string =
                                                                evt.target.value.replaceAll(
                                                                    'ー',
                                                                    '',
                                                                );
                                                            string =
                                                                string.replaceAll(
                                                                    '－',
                                                                    '',
                                                                );
                                                            string =
                                                                string.replaceAll(
                                                                    '−',
                                                                    '',
                                                                );
                                                            string =
                                                                string.replaceAll(
                                                                    '-',
                                                                    '',
                                                                );
                                                            string =
                                                                string.replaceAll(
                                                                    /[a-zA-Zぁ-んァ-ン一-龥]/g,
                                                                    '',
                                                                );
                                                            string =
                                                                fullWidthNumConvert(
                                                                    string,
                                                                );
                                                            if (
                                                                string.length >
                                                                4 &&
                                                                string.indexOf(
                                                                    '-',
                                                                ) !== 3
                                                            ) {
                                                                let txt2 =
                                                                    string.replaceAll(
                                                                        '-',
                                                                        '',
                                                                    );
                                                                txt2 =
                                                                    string.slice(
                                                                        0,
                                                                        3,
                                                                    ) +
                                                                    '-' +
                                                                    string.slice(
                                                                        3,
                                                                    );
                                                                setValue(
                                                                    'zipcode',
                                                                    txt2,
                                                                );
                                                            } else {
                                                                setValue(
                                                                    'zipcode',
                                                                    string,
                                                                );
                                                            }
                                                        }}
                                                        maxLength={8}
                                                        value={field.value}
                                                        onChange={(evt) => {
                                                            field.onChange(
                                                                evt.target
                                                                    .value,
                                                            );
                                                            setValue(
                                                                'zipCode',
                                                                evt.target
                                                                    .value,
                                                            );
                                                            clearErrors(
                                                                'zipCode',
                                                            );
                                                        }}
                                                        disabled={
                                                            isDisable
                                                        }
                                                    />
                                                    <button
                                                        onClick={() => {
                                                            if (
                                                                fieldState.error
                                                            ) {
                                                                return;
                                                            }
                                                            parsePostalCode(
                                                                getValues(
                                                                    'zipCode',
                                                                ) as string, 'customer'
                                                            );
                                                        }}
                                                        type="button"
                                                        disabled={isDisable}
                                                        className="border-[1px] rounded-[8px] text-[white] bg-[#215493] border-[#215493] !text-[16px] !p-0 !w-[200px] disabled:opacity-60"
                                                        style={{
                                                            height: '40px',
                                                        }}
                                                    >
                                                        住所自動入力
                                                    </button>
                                                </div>
                                                {errors.zipCode && (
                                                    <span className="error">
                                                        {errors.zipCode.message?.toString()}
                                                    </span>
                                                )}
                                            </div>
                                        );
                                    }}
                                />
                            </div>

                            <div className="flex flex-col col-span-2">
                                <Controller
                                    name="address"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <div className="d-flex flex-column">
                                            <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                <span className="text-red mr-1">
                                                    *
                                                </span>
                                                住所
                                            </p>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                className="textBox rounded-[2px] border-[1px] border-[#D9D9D9] px-2"
                                                {...register(
                                                    'address',
                                                    quoteValidation(
                                                        t,
                                                    ).address(),
                                                )}
                                                disabled={
                                                    isDisable
                                                }
                                                onBlur={
                                                    handleValidateWhitespace
                                                }
                                                style={{ height: '40px' }}
                                                maxLength={MAX_LENGTH}
                                            />
                                            {errors.address && (
                                                <span className="error">
                                                    {errors.address.message?.toString()}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                            </div>
                            <div className='text-[18px] font-bold col-span-4' >
                                見積情報
                            </div>
                            <div className='col-span-4' >
                                <div className="flex flex-col w-full">
                                    <div className="flex items-center mb-3">
                                        <div className="mr-4 text-base">
                                            写真アップロード
                                        </div>
                                        <img
                                            src={warningIcon}
                                            alt="warning circle icon"
                                        />
                                        <div className="text-[#808080] pl-[3px]">
                                            {' '}
                                            1:1 スケール画像
                                        </div>
                                    </div>
                                    <div>
                                        <UploadCustom
                                            locale={{
                                                uploading: 'アップロード中',
                                            }}
                                            accept=".jpg, .jpeg, .png, .gif"
                                            listType="picture-card"
                                            className="!ml-0"
                                            customRequest={dummyRequest}
                                            {...register('images', {
                                                required:
                                                    '画像は最低1枚以上アップロードしてください。',
                                            })}
                                            onChange={handleChangeFile}
                                            beforeUpload={handleBeforeUpload}
                                            fileList={listFile && listFile}
                                            multiple
                                            $isFull={
                                                typeof fileLength === 'number' &&
                                                fileLength >= 10
                                            }
                                            disabled={
                                                isDisable
                                            }
                                        >
                                            {isAllowUpload && uploadButton}
                                        </UploadCustom>
                                    </div>
                                </div>
                                {errors.images && (
                                    <span className="error">
                                        {errors.images.message?.toString()}
                                    </span>
                                )}
                            </div>
                            <div>
                                <Controller
                                    name="project_title"
                                    rules={{ required: true }}
                                    control={control}
                                    render={({ field }) => (
                                        <div className="d-flex flex-column">
                                            <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                <span className="text-red mr-1">
                                                    *
                                                </span>
                                                件名
                                            </p>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                className="textBox rounded-[2px] border-[1px] border-[#D9D9D9] px-2"
                                                {...register(
                                                    'project_title',
                                                    quoteValidation(
                                                        t,
                                                    ).project_title(),
                                                )}
                                                onBlur={
                                                    handleValidateWhitespace
                                                }
                                                disabled={
                                                    isDisable
                                                }
                                                maxLength={MAX_LENGTH}
                                            ></input>
                                            {errors.project_title && (
                                                <span className="error text-xs tracking-tight mt-1">
                                                    {errors.project_title.message?.toString()}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="flex flex-col">
                                <div className="input-add-user d-flex flex-column">
                                    <p style={{ marginTop: 0 }}>
                                        <span className="text-red mr-1">*</span>
                                        ステータス
                                    </p>
                                </div>
                                <Controller
                                    name="status"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        // <SelectSearch
                                        //     options={statusOptions}
                                        //     search
                                        //     filterOptions={handleFilter}
                                        //     {...field}
                                        //     emptyMessage={'該当なし'}
                                        //     placeholder=""
                                        //     disabled={isDisable}
                                        // />
                                        <Select
                                            options={statusOptions}
                                            // search
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            {...field}
                                            // emptyMessage={'該当なし'}
                                            style={{ height: '40px !important' }}
                                            placeholder=""
                                            disabled={isDisable}
                                        />
                                    )}
                                />
                            </div>

                            <div className="flex flex-col">
                                <div className="input-add-user d-flex flex-column">
                                    <p style={{ marginTop: 0 }}>
                                        <span className="text-red mr-1">*</span>
                                        見積種別
                                    </p>
                                </div>
                                <Controller
                                    name="quote_type"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        // <SelectSearch
                                        //     options={typeOptions}
                                        //     search
                                        //     filterOptions={handleFilter}
                                        //     {...register(
                                        //         'quote_type',
                                        //         quoteValidation(t).type(),
                                        //     )}
                                        //     {...field}
                                        //     emptyMessage={'該当なし'}
                                        //     placeholder=""
                                        //     disabled={isDisable}
                                        // />
                                        <Select
                                            options={typeOptions}
                                            // search
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            {...field}
                                            // emptyMessage={'該当なし'}
                                            style={{ height: '40px !important' }}
                                            placeholder=""
                                            disabled={isDisable}
                                        />
                                    )}
                                />
                                {errors.type && (
                                    <span className="error">
                                        {errors.type.message?.toString()}
                                    </span>
                                )}
                            </div>

                            <div className="flex flex-col">
                                <Controller
                                    name="dateTime"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <div className="d-flex flex-column w-full">
                                            <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                <span className="text-red mr-1">
                                                    *
                                                </span>
                                                工事希望日
                                            </p>
                                            <DatePicker
                                                placeholder="日付選択"
                                                {...register(
                                                    'dateTime',
                                                    quoteValidation(
                                                        t,
                                                    )?.dateTime(),
                                                )}
                                                {...field}
                                                disabled={
                                                    isDisable
                                                }
                                                format={'YYYY年MM月DD日'}
                                                style={{ height: '40px', background: '#fff', color: '#000' }}
                                                className="py-0"
                                            />

                                            {errors.dateTime && (
                                                <span className="error">
                                                    {errors.dateTime.message?.toString()}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="flex flex-col">
                                <Controller
                                    name="zipCodeConstruction"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field, fieldState }) => {
                                        const { name, onChange, onBlur, ref } =
                                            register('zipCodeConstruction', {
                                                required:
                                                    '郵便番号を入力してください。',
                                                validate: {
                                                    regex: validZipCode(
                                                        'zipcode',
                                                        t,
                                                    ),
                                                },
                                            });
                                        return (
                                            <div className="d-flex flex-column">
                                                <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                    <span className="text-red mr-1">
                                                        *
                                                    </span>
                                                    工事郵便番号
                                                </p>
                                                <div className="flex gap-[10px]">
                                                    <input
                                                        placeholder="000-0000"
                                                        {...register('zipCodeConstruction')}
                                                        className="textBox rounded-[2px] border-[1px] border-[#D9D9D9] px-2"
                                                        onBlur={(evt) => {
                                                            let string = evt.target.value.replaceAll('ー', '',);
                                                            string = string.replaceAll('－', '',);
                                                            string = string.replaceAll('−', '',);
                                                            string = string.replaceAll('-', '',);
                                                            string = string.replaceAll(/[a-zA-Zぁ-んァ-ン一-龥]/g, '',);
                                                            string = fullWidthNumConvert(string,);
                                                            if (string.length > 4 && string.indexOf('-',) !== 3) {
                                                                let txt2 = string.replaceAll('-', '',);
                                                                txt2 = string.slice(0, 3,) + '-' + string.slice(3,);
                                                                setValue('zipcodeConstruction', txt2,);
                                                            } else {
                                                                setValue('zipcodeConstruction', string,);
                                                            }
                                                        }}
                                                        disabled={isDisable}
                                                        maxLength={8}
                                                        value={field.value}
                                                        onChange={(evt) => {
                                                            field.onChange(
                                                                evt.target
                                                                    .value,
                                                            );
                                                            setValue(
                                                                'zipCodeConstruction',
                                                                evt.target
                                                                    .value,
                                                            );
                                                            clearErrors(
                                                                'zipCodeConstruction',
                                                            );
                                                        }}
                                                    />
                                                    <button
                                                        onClick={() => {
                                                            if (
                                                                fieldState.error
                                                            ) {
                                                                return;
                                                            }
                                                            parsePostalCode(
                                                                getValues(
                                                                    'zipCodeConstruction',
                                                                ) as string, 'construction'
                                                            );
                                                        }}
                                                        type="button"
                                                        className="border-[1px] rounded-[8px] text-[white] bg-[#215493] border-[#215493] !text-[16px] !p-0 !w-[200px] disabled:opacity-60"
                                                        style={{
                                                            height: '40px',
                                                        }}
                                                        disabled={isDisable}
                                                    >
                                                        住所自動入力
                                                    </button>
                                                </div>
                                                {errors.zipCodeConstruction && (
                                                    <span className="error">
                                                        {errors.zipCodeConstruction.message?.toString()}
                                                    </span>
                                                )}
                                            </div>
                                        );
                                    }}
                                />
                            </div>

                            <div className="flex flex-col">
                                <Controller
                                    name="construction_address"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <div className="d-flex flex-column">
                                            <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                <span className="text-red mr-1">
                                                    *
                                                </span>
                                                工事住所
                                            </p>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                className="textBox rounded-[2px] border-[1px] border-[#D9D9D9] px-2"
                                                {...register(
                                                    'construction_address',
                                                    quoteValidation(
                                                        t,
                                                    ).address(),
                                                )}
                                                onBlur={
                                                    handleValidateWhitespace
                                                }
                                                style={{ height: '40px' }}
                                                maxLength={MAX_LENGTH}
                                                disabled={
                                                    isDisable
                                                }
                                            />
                                            {errors.construction_address && (
                                                <span className="error">
                                                    {errors.construction_address.message?.toString()}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                            </div>

                            <div className="flex flex-col">
                                <div className="input-add-user d-flex flex-column">
                                    <p style={{ marginTop: 0 }}>
                                        協力会社
                                    </p>
                                </div>
                                <Controller
                                    name="cooperative_company_id"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            options={partnerOptions}
                                            showSearch
                                            // search
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            {...field}
                                            // emptyMessage={'該当なし'}
                                            style={{ height: '40px !important' }}
                                            placeholder=""
                                            disabled={isDisable}
                                        />
                                        // <SelectSearch
                                        //     options={[
                                        //         ...OPTION_NONE, ...partnerOptions]}
                                        //     search
                                        //     filterOptions={handleFilter}
                                        //     {...field}
                                        //     emptyMessage={'該当なし'}
                                        //     placeholder=""
                                        //     disabled={isDisable}
                                        // />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="flex flex-col w-full mt-[20px] gap-y-[30px]">
                            <div className="flex flex-col">
                                <Controller
                                    name="content"
                                    rules={{ required: true }}
                                    control={control}
                                    render={({ field }) => (
                                        <div className="d-flex flex-column">
                                            <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                <span className="text-red mr-1">
                                                    *
                                                </span>
                                                依頼内容
                                            </p>
                                            <textarea
                                                autoComplete="off"
                                                className="rounded-[2px] border-[1px] border-[#D9D9D9] p-[5px]"
                                                rows={4}
                                                {...register(
                                                    'content',
                                                    quoteValidation(
                                                        t,
                                                    ).content(),
                                                )}
                                                onBlur={
                                                    handleValidateWhitespace
                                                }
                                                maxLength={MAX_LENGTH}
                                                disabled={
                                                    isDisable
                                                }
                                            ></textarea>
                                            {errors.content && (
                                                <span className="error">
                                                    {errors.content.message?.toString()}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="flex flex-col mt-[10px]">
                                <Controller
                                    name="note"
                                    control={control}
                                    render={({ field }) => (
                                        <>
                                            <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                注記
                                            </p>
                                            <textarea
                                                {...field}
                                                className="rounded-[2px] border-[1px] border-[#D9D9D9] p-[5px]"
                                                rows={4}
                                                maxLength={wordLimit}
                                                disabled={
                                                    screenType === TYPE_OF_SCREEN.detail
                                                }
                                                onBlur={
                                                    handleValidateWhitespace
                                                }
                                            ></textarea>
                                            <p className="text-right text-[#00000040]">
                                                {field?.value !== undefined
                                                    ? field?.value?.length
                                                    : 0}
                                                /{wordLimit}
                                            </p>
                                        </>
                                    )}
                                />
                            </div>
                        </div>
                    </div>

                    <div
                        className="text-lg flex flex-row flex-wrap max-md:justify-center gap-[10px]"
                        style={{
                            marginTop: '14px',
                        }}
                    >
                        <button
                            className="modal-create-user-button1 !text-[#000000D9] d-flex flex-row !rounded-[2px] !w-[146px]"
                            type="button"
                            onClick={() => navigate('/quote/list')}
                        >
                            {screenType === TYPE_OF_SCREEN.detail ? '戻る' : 'キャンセル'}
                        </button>
                        {screenType !== 'detail' && <button
                            className="modal-create-user-button2 d-flex flex-row !rounded-[2px] !w-[146px]"
                            type="submit"
                        >
                            保存
                        </button>}
                    </div>
                </form>
            </div>
        </QuoteFormStyles>
    );
};

const QuoteForm: FC<ReceivedProps> = (props) => {
    return <QuoteFormLayout {...useQuoteForm(props)} />;
};

const UploadCustom = styled(Upload) <{ $isFull?: boolean }>`
    .ant-upload-list-picture-card-container:nth-child(
            ${(props) => (props.$isFull ? '3n' : '3n + 2')}
        ) {
        margin-right: 0px;
    }
    .ant-upload-list-picture-card .ant-upload-list-item {
        border: none;
        padding: 0px;
    }
    .ant-upload-list-picture-card .ant-upload-list-item-info {
        border-radius: 6.55px;
        border: 1px solid #dcdcdc;
    }
    .ant-upload-list {
        width: 332px;
        // margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
    }
    .ant-upload-select-picture-card {
        order: -1;
    }
            .ant-upload-list {
    width: auto !important;
    max-width: 100% !important;
    }
`;

export default QuoteForm;
