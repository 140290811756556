import React from 'react'
import { FC } from 'react';
import useProjectDetail, { Props, ReceivedProps } from './hook';
import ProgressStep from './progressStep';
import { useParams } from 'react-router-dom';
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import uploadIcon from 'assets/icons/addImageFile.svg';
import warningIcon from 'assets/icons/WarningCircle.svg';
import styled from 'styled-components';
import { DatePicker, message, Select, Upload } from 'antd';
import { RcFile, UploadChangeParam, UploadFile } from 'antd/lib/upload';
import BluePen from 'assets/icons/blue-pen.png'
import BlackPlus from 'assets/icons/black-plus.png'
import { formatCurrency } from 'utils/helper/currency';
import { QuoteStatusEnum } from 'utils/enums';
import moment from 'moment';
import { DATE_FORMAT } from 'pages/project/projectPlan/projectTabs/constant';

export const ProjectTabelRow = (props) => {
    const { title, value } = props;
    return (
        <div className="project-detail-inf-row-sp d-flex flex-col md:flex-row">
            <div className="project-detail-inf-row-title-sp-2 max-md:py-[10px] w-full md:w-[174px] !text-[16px]">{title}</div>
            <div className="project-detail-inf-row-content-sp !leading-6 p-2 line-clamp-4 text-ellipsis overflow-hidden !text-[16px]">{value}</div>
        </div>
    );
};

const ProjectDetailLayout: FC<Props> = ({
    control,
    dragProps,
    dummyRequest,
    setValue,
    register,
    clearErrors,
    watch,
    getValues,
    setError,
    errors,
    navigate,
    fileLength,
    isAllowUpload,
    listFile,
    setFileLength,
    setAllowUpload,
    setListFile,
    totalImageSize,
    setTotalImageSize,
    setIndexError,
    indexError,
    sizeUploadLimitError, setSizeUploadLimitError,
    projectDetail,
    handleSubmit,
    submitData,
}) => {
    const { id } = useParams()
    const UPLOAD_LIMIT = 10;

    const uploadButton = (
        <div className="text-[#000000D9] flex flex-col items-center">
            <img
                src={BlackPlus}
                className="w-[16px] h-[16px]"
                alt="upload image icon"
            />
            <div>ファイルを選択</div>
        </div>
    );

    const handleBeforeUpload = (file: RcFile, fileList: RcFile[]) => {
        const uploadingSizes = fileList.reduce((accu: number, curr: RcFile) => {
            return accu + curr.size;
        }, 0);
        setIndexError(0);
        const accumulatedImageSize = totalImageSize + uploadingSizes;
        const isImage =
            file.type === 'image/jpeg' ||
            file.type === 'image/png' ||
            file.type === 'image/gif';
        if (!isImage) {
            message.error(
                'jpg、.jpeg、.png、.gif形式でアップロードしてください。',
            );
            return Upload.LIST_IGNORE;
        }

        const isUnderLimit = accumulatedImageSize < 1024 * 1024 * UPLOAD_LIMIT;
        if (!isUnderLimit) {
            setSizeUploadLimitError(true);
            return Upload.LIST_IGNORE;
        }

        return isImage && isUnderLimit;
    };

    const handleChangeFile = (info: UploadChangeParam<UploadFile<any>>) => {
        const loadFile = 10;
        setIndexError(0);
        const filteredPdfAfterRedundant = info?.fileList.filter(
            (bItem) => !listFile?.some((aItem) => aItem.uid === bItem.uid),
        );

        if (info.file.status === 'done') {
            setTotalImageSize(
                (prevSize) => prevSize + (info.file.size as number),
            );
        }
        if (info.file.status === 'removed') {
            setTotalImageSize(
                (prevSize) => prevSize - (info.file.size as number),
            );
        }
        if (loadFile >= info?.fileList?.length) {
            setAllowUpload(info.fileList.length < 10);
            setValue('images', info.fileList);
            setFileLength(info?.fileList?.length);
            setListFile(info?.fileList);
        } else {
            setIndexError((index) => index + 1);
        }
        clearErrors('images');
    };
    return (
        <div className='m-[30px] bg-[#fff]' >
            <div className='p-[15px]' >
                <div className='h-[40px]' />
                <div className='max-md:max-w-[400px] max-md:overflow-x-scroll' >
                    <div className='max-md:min-w-[1000px] max-md:h-[120px]' >
                        <ProgressStep
                            // readOnly
                            business_type={true}
                            project_id={Number(id)}
                            status={projectDetail.status}
                        />
                    </div>
                </div>

                <div onClick={() => navigate(`/quote/project/${id}/edit`)}
                    className='flex flex-row justify-end gap-[10px] mb-[20px] cursor-pointer' >
                    <img src={BluePen} alt="" height="17px" width="20px" />
                    <p className='text-[17px] text-[#215493] !mb-0' >編集</p>
                </div>
                <Grid
                    container
                    sx={{
                        border: '1px solid #CDD1D5',
                        borderRadius: '5px',
                        mb: '47px',
                        '.MuiGrid-item': {
                            borderBottom: '1px solid #f5f5f5',
                        },
                        '.MuiGrid-item:nth-child(7)': {
                            border: 'none',
                        },
                        '.MuiGrid-item:nth-child(8)': {
                            border: 'none',
                        },
                    }}
                >
                    <div className='flex flex-col md:flex-row w-full' >
                        <ProjectTabelRow
                            title="案件ID"
                            value={projectDetail?.id}
                        />
                        <ProjectTabelRow
                            title="ステータス"
                            value={QuoteStatusEnum[
                                projectDetail.status
                            ]?.title}
                        />
                    </div>
                    <div className='flex flex-col md:flex-row w-full' >
                        <ProjectTabelRow
                            title="案件名"
                            value={projectDetail?.project_title}
                        />

                        <ProjectTabelRow
                            title="期間"
                            value={
                                <div className="flex flex-row">
                                    {moment(projectDetail?.desired_date).format(DATE_FORMAT)} ~ {projectDetail?.desired_end_date && moment(projectDetail?.desired_end_date).format(DATE_FORMAT)}
                                </div>
                            }
                        />
                    </div>
                    <div className='flex flex-col md:flex-row w-full' >
                        <ProjectTabelRow
                            title="協力会社"
                            value={projectDetail?.cooperative_company?.company_name}
                        />
                        <ProjectTabelRow
                            title="案件管理者 "
                            value={projectDetail?.staff?.name}
                        />
                    </div>
                    <div className='flex flex-col md:flex-row w-full' >
                        <div className="project-detail-inf-row-sp d-flex flex-col md:flex-row">
                            <div className="project-detail-inf-row-title-sp-2 max-md:py-[10px] w-full md:w-[174px] !text-[16px]">契約金額</div>
                            <div className="project-detail-inf-row-content-sp !leading-6 p-2 justify-end items-end">
                                <span className='mx-[5px] !text-[16px]' >{formatCurrency(projectDetail?.invoice?.total_estimate_after_tax)}</span>
                            </div>
                        </div>

                        <div className="project-detail-inf-row-sp d-flex flex-col md:flex-row">
                            <div className="project-detail-inf-row-title-sp-2 w-full md:w-[174px] !text-[16px]"></div>
                            <div className="project-detail-inf-row-content-sp !leading-6 p-2 justify-end items-end">
                                <span className='mx-[5px] !text-[16px]' ></span>
                            </div>
                        </div>
                    </div>
                </Grid>
                <form onSubmit={handleSubmit(submitData)} >
                    <div className="flex flex-col w-full">
                        <div className="flex items-center mb-3">
                            <div className="mr-4 text-base">
                                アップロード
                            </div>
                        </div>
                        <WrapperStyle className='flex flex-row gap-[15px]' >
                            <UploadCustom
                                locale={{
                                    uploading: 'アップロード中',
                                }}
                                accept=".jpg, .jpeg, .png, .gif"
                                listType="picture-card"
                                className="!ml-0"
                                customRequest={dummyRequest}
                                {...register('images', {
                                    required:
                                        '画像は最低1枚以上アップロードしてください。',
                                })}
                                style={{ width: 'auto' }}
                                onChange={handleChangeFile}
                                beforeUpload={handleBeforeUpload}
                                fileList={listFile && listFile}
                                multiple
                                $isFull={
                                    typeof fileLength === 'number' &&
                                    fileLength >= 10
                                }
                            // disabled={
                            //     isDisable
                            // }
                            >
                                {isAllowUpload && uploadButton}
                            </UploadCustom>
                            {/* <div className="flex items-center w-[166px] mb-3">
                                <div className="mr-4 text-base text-[#00000073]">
                                    推奨解像度は 640*640、ファイル サイズは 2MB
                                    まで、被写体を中央に配置してください。
                                </div>
                            </div> */}
                        </WrapperStyle>

                        <div
                            className="text-lg flex flex-row gap-[10px] mt-[30px]"
                        >
                            <button
                                className="modal-create-user-button1 !text-[#000000D9] d-flex flex-row !rounded-[2px] !w-[146px]"
                                type="button"
                                onClick={() => navigate(`/quote/list`)}
                            >
                                戻る
                            </button>
                            <button
                                className="modal-create-user-button2 d-flex flex-row !rounded-[2px] !w-[146px]"
                                type="submit"
                            >
                                保存
                            </button>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    )
}

const ProjectDetail: FC<ReceivedProps> = (props) => {
    return <ProjectDetailLayout {...useProjectDetail(props)} />;
};

const UploadCustom = styled(Upload) <{ $isFull?: boolean }>`
    .ant-upload-list-picture-card-container:nth-child(
            ${(props) => (props.$isFull ? '3n' : '3n + 2')}
        ) {
        margin-right: 0px;
    }
    .ant-upload-list-picture-card .ant-upload-list-item {
        border: none;
        padding: 0px;
    }
    .ant-upload-list-picture-card .ant-upload-list-item-info {
        border-radius: 6.55px;
        border: 1px solid #dcdcdc;
    }
    .ant-upload-list {
        width: 332px;
        // margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
    }
    .ant-upload-select-picture-card {
        order: -1;
    }
    .ant-upload-picture-card-wrapper {
        width: auto !important;
        
    }
    .ant-upload-list {
    width: auto !important;
    max-width: 100% !important;
    }
`;

const WrapperStyle = styled.div`
    .ant-upload-picture-card-wrapper {
        width: auto !important;
        
    }
    .ant-upload-list {
    width: auto !important;
    max-width: 100% !important;
    }
`

export default ProjectDetail;