import { Upload, UploadProps, message } from 'antd';
import { useState, useEffect, FocusEventHandler } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { RcFile } from 'antd/lib/upload';
import { alertError, alertSuccessPopup } from 'utils/helper/appHelper';
import quoteApi from 'services/quoteApi';
import { validateFullWhitespace } from 'utils/validation/validatorHelper';
import moment from 'moment';

export type ReceivedProps = {
    screenType: 'create' | 'edit';
};
const useSPQuoteForm = (props: ReceivedProps) => {
    const params = useParams();
    const { id }: any = params;
    const {
        control,
        handleSubmit,
        watch,
        register,
        getValues,
        setError,
        setValue,
        formState: { errors },
        resetField,
        clearErrors,
    } = useForm();
    const numberOfSelectedImages = watch('images');
    let storage = localStorage.getItem('line:accessToken');
    // const user = storage !== null ? JSON.parse(storage) : null;
    const [data, setData]: any = useState();
    const [isAllowUpload, setAllowUpload] = useState(true);

    const [isLoading, setIsLoading] = useState(false);
    const [listFile, setListFile]: any = useState();
    const [totalImageSize, setTotalImageSize]: any = useState<number>(0);
    const [fileLength, setFileLength]: any = useState(0);
    const [isExcessiveUpload, setIsExcessiveUpload] = useState<boolean>(false);
    const location = useLocation();
    const navigate = useNavigate();
    const getDetail = async () => {
        try {
            const res = await quoteApi.getSPQuoteDetail(storage, id);
            if (res) {
                const data = res.data.quote;
                setData(data);
                setValue('note', data?.note === 'undefined' ? '' : data?.note);
                setValue('quote_type', data?.quote_type);
                setValue('content', data?.content);
                setValue('zipCode', data?.zipcode);
                setValue('address', data?.address);
                setValue('project_title', data?.project_title);
                data?.desired_date &&
                    setValue('dateTime', moment(data?.desired_date));
                setValue('status', data?.status);
                setAllowUpload(data.images.length < 10);
                // console.log('data?.images[0].image_path', data?.images[0].image_path)
                const fileArr: any = [];
                let imageSize = 0;
                if (data?.images && data?.images.length > 0) {
                    for (let i = 0; i < data?.images.length; i++) {
                        await fetch(data?.images[i].image_path)
                            .then((res) => res.blob())
                            .then((myBlob) => {
                                const myFile: any = new File(
                                    [myBlob],
                                    'image.jpeg',
                                    { type: myBlob.type },
                                );
                                // const newFile = {
                                //     uid: '-3',
                                //     name: myFile.name,
                                //     // lastModified: myFile.lastModified,
                                //     // lastModifiedDate: myFile.lastModifiedDate,
                                //     // size: myFile.size,
                                //     // type: myFile.type,
                                //     // file: myFile.file,
                                //     url: data?.images[0].image_path,
                                //     status: 'done',
                                //     // originFileObj: myFile
                                // }
                                // fileArr.push(newFile)
                                fileArr.push({
                                    uid: `-${i + 1}`,
                                    name: myFile.name,
                                    url: data?.images[i].image_path,
                                    status: 'done',
                                    originFileObj: myFile,
                                    lastModified: myFile.lastModified,
                                    lastModifiedDate: myFile.lastModifiedDate,
                                    type: myFile.type,
                                    size: myFile.size,
                                });
                                imageSize += myFile.size;
                            });
                    }
                }
                setTotalImageSize((prev) => prev + imageSize);
                setValue('images', fileArr);
                setListFile(fileArr);
                setFileLength(fileArr.length);
            }
        } catch (error) {
            console.log('error', error);
        }
    };

    useEffect(() => {
        if (id) {
            getDetail();
        }
    }, [id]);

    const submitData = async (value: any, evt: any) => {
        const isAllLE50MB = totalImageSize < 1024 * 1024 * 50;

        evt.preventDefault();
        setIsLoading(true);
        let formData = new FormData();
        if (value?.file) {
            formData.append('file', value?.file);
        }
        formData.append('desired_date', value?.dateTime);
        formData.append('zipcode', value?.zipCode);
        formData.append('content', value?.content);
        formData.append('title', '');
        formData.append('project_title', value?.project_title);
        formData.append('address', value?.address);
        for (let i = 0; i < value?.images?.length; i++) {
            formData.append('images[]', value?.images[i].originFileObj);
        }

        formData.append('note', value?.note);
        formData.append('quote_type', value?.quote_type);
        if (!isAllLE50MB) {
            message.error('画像の合計サイズは 50MB を超えてはなりません');
            setIsLoading(false);
        } else {
            try {
                // const body: any = {
                //     title: value?.title,
                //     address: value?.address,
                //     images: value?.images,
                //     note: value?.note,
                //     quote_type: value?.quote_type,
                //     file: formData
                // }
                if (location?.pathname?.includes('create')) {
                    const res = await quoteApi.createSPQuote(storage, formData);
                    if (res) {
                        alertSuccessPopup({
                            title: '見積依頼が送信されました。',
                            confirmButtonText: '閉じる',
                        });
                        handleResetFields();
                        setIsLoading(false);
                        setTotalImageSize(0);
                    }
                } else {
                    const res = await quoteApi.updateSPQuote(
                        storage,
                        formData,
                        id,
                    );
                    if (res) {
                        // alertSuccess('見積書が正常に送信されました');
                        handleResetFields();
                        navigate(-1);
                        setIsLoading(false);
                        setTotalImageSize(0);

                    }
                }
            } catch (error) {
                console.log('error', error.message);
                setIsLoading(false);
                // alertError(error.message);
            }
        }
    };
    const dragProps: UploadProps = {
        name: 'file',
        beforeUpload(file: RcFile) {
            const isPDF = file.type === 'application/pdf';

            const isLE10MB = file.size / 1024 / 1024 <= 10;
            if (!isLE10MB) {
                message.error('PDFファイルは10MBを超えてはいけません。');
            }
            return (!isPDF && isLE10MB) || Upload.LIST_IGNORE;
        },
        onChange(info) {
            // getBase64(info.file.originFileObj as RcFile, (url) => {
            //     setValue('file', url)
            // });
            setValue('file', info.file.originFileObj);
        },
        onDrop(e) {
            //
        },
    };
    const dummyRequest: UploadProps['customRequest'] = async ({
        file,
        onSuccess,
    }) => {
        setTimeout(() => {
            if (onSuccess) {
                onSuccess('ok');
            }
        }, 0);
    };

    const handleValidateWhitespace: FocusEventHandler<
        HTMLInputElement | HTMLTextAreaElement
    > = (e) => {
        const onlyContainWhitespaces = validateFullWhitespace(e.target.value);
        if (onlyContainWhitespaces) {
            setValue(e.target.name, '');
        } else {
            setValue(e.target.name, e.target.value.trim());
        }
    };

    const handleResetFields = () => {
        setValue('content', '');
        setValue('dateTime', '');
        setValue('zipCode', '');
        setValue('note', '');
        setValue('address', '');
        setValue('project_title', '');
        resetField('images');
        setValue('quote_type', 'interior');
        setListFile([]);
        setFileLength(undefined);
        setIsLoading(false);
        setAllowUpload(true);
    };

    const navigateBackToDetail = () => {
        navigate(-1);
    };

    return {
        ...props,
        numberOfSelectedImages,
        handleSubmit,
        submitData,
        control,
        dragProps,
        dummyRequest,
        register,
        setError,
        errors,
        setValue,
        id,
        isLoading,
        getValues,
        listFile,
        setListFile,
        isAllowUpload,
        setAllowUpload,
        fileLength,
        setFileLength,
        isExcessiveUpload,
        setIsExcessiveUpload,
        data,
        clearErrors,
        navigateBackToDetail,
        handleValidateWhitespace,
        totalImageSize,
        setTotalImageSize,
    };
};

export type Props = ReturnType<typeof useSPQuoteForm>;

export default useSPQuoteForm;
